import React, { useEffect, useState } from 'react'
import { BarChart } from '@mui/x-charts/BarChart'
import { LineChart } from '@mui/x-charts/LineChart'
import { getDayMonthFromMs } from '../../../Utils/time'
import { ZENZE_BASE_URL } from '../../../api/Constants'

function ZenzeUsers30Days() {
  const [apiCalled, setApiCalled] = useState(false)
  const [labelArray, setLabelArray] = useState([])
  const [countArray, setCountArray] = useState([])
  const [chartType, setChartType] = useState('bar') // State to toggle between bar and line chart

  const handleTicketStats = (ticketStatsList) => {
    const labels = []
    const counts = []
    ticketStatsList.forEach((ticket) => {
      labels.push(getDayMonthFromMs(ticket.time))
      counts.push(ticket.count)
    })
    setLabelArray(labels)
    setCountArray(counts)
  }

  const getFeedbackRatingStats = () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }

    fetch(`${ZENZE_BASE_URL}/api/stats/v1/users-count`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log('Data : ', data)
        if (data.status === 200) {
          setApiCalled(true)
          handleTicketStats(data.data.aggregationResults)
        }
      })
      .catch((error) => {
        console.log('Error : ', error)
      })
  }

  useEffect(() => {
    if (!apiCalled) {
      getFeedbackRatingStats()
    }
  }, [apiCalled])

  const toggleChartType = () => {
    setChartType((prevType) => (prevType === 'bar' ? 'line' : 'bar'))
  }

  return (
    <>
      <div>
        <p className='text-center text-4xl font-bold mt-12'>Zenze Users - 30 days</p>
        <div className='text-center mt-4'>
          <button
            onClick={toggleChartType}
            className='bg-gray-700 text-white px-4 py-2 rounded'>
            Toggle to {chartType === 'bar' ? 'Line' : 'Bar'} Chart
          </button>
        </div>
      </div>
      {labelArray.length > 0 && (
        <div className='pt-8'>
          {chartType === 'bar' ? (
            <BarChart
              xAxis={[
                {
                  id: 'barCategories',
                  data: labelArray,
                  scaleType: 'band'
                }
              ]}
              series={[
                {
                  data: countArray,
                  label: 'New users each day',
                  color: '#33414d'
                }
              ]}
              height={500}
              sx={{ margin: '10px' }}
            />
          ) : (
            <LineChart
              xAxis={[
                {
                  id: 'lineCategories',
                  data: labelArray,
                  scaleType: 'point'
                }
              ]}
              series={[
                {
                  data: countArray,
                  label: 'Users created each day',
                  color: '#33414d'
                }
              ]}
              height={500}
              sx={{ margin: '10px' }}
            />
          )}
        </div>
      )}
    </>
  )
}

export default ZenzeUsers30Days
