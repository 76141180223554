import secureLocalStorage from 'react-secure-storage'
import * as React from 'react';
import { useEffect } from 'react';
import { Autocomplete, Button, Card, CardContent, CardHeader, CardMedia, Grid, Input, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import MuiAlert from '@mui/material/Alert';

import DeleteIcon from '@mui/icons-material/Delete';

import { useNavigate } from 'react-router-dom';

import ReactGA from "react-ga";

import './Community.css';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Community = () => {

    const [apiCalled, setApiCalled] = useState(false);

    const [revenueData, setRevenueData] = useState([]);
    const [revenueTableData, setRevenueTableData] = useState([]);

    const [posts, setPosts] = useState([]);
    const [inputUsername, setInputUsername] = useState("");

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [currentDataSetType, setCurrentDataSetType] = useState("Combined");

    let navigate = useNavigate();
    const token = secureLocalStorage.getItem("token");
    const dashboardUid = secureLocalStorage.getItem("uid");

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const resetAllFields = () => {
        setInputUsername("");
    }

    const getPostsHanlder = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "userName": inputUsername, "dashboardUid": dashboardUid })
        };

        fetch('https://healify.blockerx.net/api/dashboard/community/getAllPosts', requestOptions)
            .then(response => response.json())
            .then((data) => {
                if (data.status === "success" && data.data.length) {
                    setApiCalled(true);
                    setPosts(data.data);
                } else if (data.status === "invalid_token") {
                    secureLocalStorage.clear();
                    navigate("/tokenExpired");
                } else {
                    setOpenErrorAlert(true);
                    resetAllFields();
                }
            });

    }

    const deletePostHandler = (event) => {
        const postId = event.target.parentElement.parentElement.parentElement.parentElement.parentElement.id;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "postId": postId, "dashboardUid": dashboardUid })
        };

        fetch('https://healify.blockerx.net/api/dashboard/community/deletePost', requestOptions)
            .then(response => response.json())
            .then((data) => {
                if (data.status === "success") {
                    setOpenSuccessAlert(true);
                    getPostsHanlder()
                } else if (data.status === "invalid_token") {
                    secureLocalStorage.clear();
                    navigate("/tokenExpired");
                } else {
                    setOpenErrorAlert(true);
                    resetAllFields();
                }
            });
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    return (
        <Box sx={{ flexGrow: 2 }}>
            <Grid align="center" justify="center" spacing={2}>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <h1 className="header-top mt-5">Get All Posts of User</h1>
                </Grid>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <TextField
                        label="Enter username"
                        value={inputUsername}
                        sx={{ width: "450px", margin: "1%" }}
                        onChange={(event, item) => {
                            setInputUsername(event.target.value);
                        }}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <Button
                        className="h-12"
                        variant="contained"
                        sx={{ width: "450px" }}
                        onClick={getPostsHanlder}>
                        Show Posts
                    </Button>
                </Grid>

                {apiCalled === false ? <></> : ''}
                {apiCalled === true ? <></> : ''}

                <Box sx={{ marginTop: '30px', marginBottom: '50px' }} >
                    {posts.length > 0 ? <h1 className='text-4xl text-center my-5'>User Streaks</h1> : " "}
                    {posts.map((post) => {
                        if (post.type === "text") {
                            return (
                                <Grid className='outerGrid' align="center" justify="center" sx={{ width: "600px", margin: "0 auto" }} spacing={2} id={post._id}>
                                    <Grid item lg={4} md={6} sm={12} className="pt-6" sx={{ width: "600px" }}>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            <Grid item xs={6}>
                                                <Card sx={{ width: "600px", padding: "10px" }}>
                                                    <CardHeader
                                                        title={post.title}
                                                        align="center"
                                                        subheader={post.desc}
                                                    />
                                                </Card>
                                            </Grid>
                                            <Grid item>
                                                <DeleteIcon className="hover:scale-125 cursor-pointer deleteIcon text-red-600" onClick={deletePostHandler} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        } else if (post.type === "image") {
                            return (
                                <Grid className='outerGrid' align="center" justify="center" sx={{ width: "600px", margin: "0 auto" }} spacing={2} id={post._id}>
                                    <Grid item lg={4} md={6} sm={12} className="pt-6" sx={{ width: "600px" }}>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            <Grid item xs={6}>
                                                <Card sx={{ width: "600px", padding: "10px" }}>
                                                    <CardHeader
                                                        title={post.title}
                                                        align="center"
                                                    />
                                                    <CardMedia
                                                        component="img"
                                                        height="194"
                                                        image={post.desc}
                                                        alt=""
                                                    />
                                                </Card>
                                            </Grid>
                                            <CardContent>
                                                <Typography variant="body2" color="text.secondary">
                                                </Typography>
                                            </CardContent>
                                            <Grid item>
                                                <DeleteIcon className="hover:scale-125 cursor-pointer deleteIconImage text-red-600" onClick={deletePostHandler} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        } else if (post.type === "polling") {
                            return (
                                <Grid className='outerGrid' align="center" justify="center" sx={{ width: "600px", margin: "0 auto" }} spacing={2} id={post._id}>
                                    <Grid item lg={4} md={6} sm={12} className="pt-6" sx={{ width: "600px" }}>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            <Grid item xs={6}>
                                                <Card sx={{ width: "600px", padding: "10px" }}>
                                                    <CardHeader
                                                        title={post.title}
                                                        align="center"
                                                    />
                                                    <CardContent>
                                                        <Typography variant="body2" color="text.secondary">
                                                            {post.option1}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            {post.option2}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            {post.option3}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            {post.option4}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item>
                                                <DeleteIcon className="hover:scale-125 cursor-pointer deleteIconPoll text-red-600" onClick={deletePostHandler} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        } else {
                            return (<></>);
                        }
                    })}
                </Box>
            </Grid>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                        Success!!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                        Error!!!
                    </Alert>
                </Snackbar>
            </Stack>
        </Box>
    )
}

export default Community;