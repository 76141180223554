exports.cannedResponseAutocomplete = [
    {
        label: '/ios-cancellation',
        text: "Dear user,\n\nThanks for reaching out to us. We apologize for the inconvenience.\n\nWe'd like to inform you that we won't be able to cancel subscriptions for iOS as the developer doesn't get access to any of the order details of a user's subscription.\n\nWe kindly request you to cancel the subscription by following these steps: https://support.apple.com/en-in/HT202039 and request a refund via this link: https://support.apple.com/en-in/HT204084"
    },
    {
        label: '/follow-up',
        text: 'Hey there, Just following up to check if you received our previous response.'
    },
    {
        label: '/more-details',
        text: "Dear user,\n\nThank you for reaching out to us. We apologize for the inconvenience you have experienced.\n\nWe fully understand the importance of resolving the issue you're facing, and we are committed to providing you with the best possible assistance. To assist us in troubleshooting and resolving the problem more effectively, we kindly request that you provide us with additional details.\n\nCould you please provide us with more information about the issue you're encountering? It would be greatly helpful if you could create a screen recording, attach it to Google Drive, and then send us the link to the screen recording. This will enable us to view the issue firsthand and provide you with a more tailored and efficient solution.\n\nWe look forward to receiving the requested information from you so that we can promptly address and resolve the problem.\n\nThank you for your understanding and patience."
    },
    {
        label: '/refund-cancellation',
        text: "Dear user,\n\nThanks for reaching out to us. We apologize for the inconvenience.\n\nIf you are seeking a refund or wish to cancel your subscription, please visit your account's dashboard to check eligibility and initiate the process. You can access your account at https://accounts.blockerx.net/signin. If you have any difficulties or further questions, feel free to let us know.\n\nThank you."
    },
    {
        label: '/playstore-prevent-uninstall',
        text: "Dear user,\n\nThanks for reaching out to us. We apologize for the inconvenience.\n\nYou can prevent uninstall of BlockerX app by installing our APK - https://blockerx.onelink.me/oeB0/uninstallemail  \n\nYou need to first uninstall the existing playstore version and then install the APK version.\n\nOnce you've installed the APK version, you should be able to take advantage of the complete functionality of BlockerX. The Google play version doesn't have the prevent uninstall feature as we need to comply with Google's policies. If you have any further questions or concerns, please don't hesitate to contact us. We're here to help!\n\nThank you."
    },
    {
        label: '/accountability-partner-remove',
        text: "Dear user,\n\nThanks for reaching out to us. We apologize for the inconvenience. \n\nIf your accountability partner is not responding you can remove the partner by clicking on the following link: https://accounts.blockerx.net/signin \n\nFrom there, you'll be able to access all the necessary tools to modify or review your accountability partner settings. If you encounter any difficulties or have additional questions, please feel free to get in touch with us. We're here to assist you!\n\nThank you."
    },
    {
        label: '/no-change',
        text: "Dear user,\n\nThanks for reaching out to us.We apologize for the inconvenience.\n\nPlease note that we cannot turn off or edit a user's account settings or blocklist, as our users have complete control over their accounts.\n\nPlease follow the in-app instructions to edit your blocklist.\n\nThank you"
    },
    {
        label: '/playstore-version-accountability-partner',
        text: "Dear user,\n\nThanks for reaching out to us. We apologize for the inconvenience.\n\nWe have reviewed your account and it appears that you are currently using the Google Play Store version of BlockerX. We'd like to inform you that due to the stringent policies set by the Play Store, certain features of BlockerX, including the Accountability Partner feature, may not function as intended.\n\nTo fully utilize all the advanced functions of BlockerX, we recommend downloading the APK version of the app. You can do so by following this link: https://blockerx.onelink.me/oeB0/uninstallemail. This version provides you with access to all features, including those that might be restricted in the Play Store version.\n\nOnce you've installed the APK version, you should be able to take advantage of the complete functionality of BlockerX. If you have any further questions or concerns, please don't hesitate to contact us. We're here to help!\n\nThank you."
    },
    {
        label: '/power-saving-mode',
        text: "Dear user,\n\nThank you for reaching out to us. It seems like you're facing an issue with the BlockerX program when trying to watch explicit content using power saver mode. We understand that this can be a challenging situation, and we're here to assist you.\n\nTo address this issue, you have a couple of options:\n\n1. Block the Settings App: You can block access to the Settings app using BlockerX. By doing this, you won't be able to activate the power saver mode or make changes to your device settings that could interfere with BlockerX.\n\n2. Enable 'Block Notification Drawer': Within BlockerX settings, you can enable the 'Block Notification Drawer' option. This prevents you from accessing the power saving mode or making any changes through the notification drawer, ensuring that the power saver mode cannot be activated while BlockerX is active.\n\nBoth of these options can help you maintain a more consistent and effective use of BlockerX to support your goals. If you're unsure about how to implement these changes, please let us know, and we'd be happy to guide you through the process step by step.\n\nIf you have any further questions or concerns, don't hesitate to reach out. We're here to help you make the most out of your BlockerX"
    },
    {
        label: '/language-change',
        text: `Dear user,\n\nThanks for reaching out to us. We apologize for the inconvenience.\n\nTo change the language of the BlockerX application follow these steps:\n\n\nClick on (3 lines) located at the top left corner ---> Go to the "Settings Tab" (Last Tab) in the list ---> Click on the "Change language" (second tab in the list) Tab and select the language of your choice from the list. --->Submit on Confirm.\n\n\nHope this resolves the issue.\n\nThank you`
    },
    {
        label: '/outdated-version',
        text: "Dear user,\n\nThanks for reaching out to us. We apologize for the inconvenience.\n\nAfter reviewing your concern, it appears that the version of BlockerX you're using is outdated. To resolve this issue, we recommend uninstalling the current version of BlockerX from your device. You can then proceed to download the latest version by clicking on the following link: https://blockerx.onelink.me/oeB0/uninstallemail \n\n Once you've installed the latest version, please check if the issue persists. If you encounter any further difficulties or have additional questions, please don't hesitate to contact us. We're here to ensure that your experience with BlockerX is seamless and effective.\n\nThank you for your understanding and cooperation."
    },
    {
        label: '/windows-app-link',
        text: "Dear user,\n\nThanks for reaching out to us. We apologize for the inconvenience.\n\nHere's the link to download the Windows app for BlockerX: https://blockerx.onelink.me/oeB0/upsellingwapp \n\nThank you."
    },
    {
        label: '/restricted-setttings',
        text: "I understand that you're encountering a message indicating that the app's permissions are restricted due to security concerns. To address this issue, I recommend trying the following steps: \n\n1. Open the Settings app on your device. \n2. Navigate to the Apps or Applications section, where you can view a list of installed applications. \n3. Locate and select BlockerX from the list. \n4. Once you're on the BlockerX app info screen, you might find a menu represented by three vertical dots or lines on the top right corner of the screen. Tap on these dots. \n5. From the dropdown menu that appears, look for an option like 'Allow Restricted Settings' or something similar. This option might be worded differently depending on your device's interface. \n6. If you find the 'Allow Restricted Settings' option, tap on it. This action should grant the app additional permissions that are currently restricted due to security concerns. \n7. Once you've allowed restricted settings, go back to the app and attempt to enable the necessary permissions for BlockerX again.\n\nPlease note that the steps might vary slightly based on your device model and Android version.\n\nI hope these steps help resolve the issue you're facing. If you need further assistance, feel free to ask.\n\nThank you."
    },
    {
        label: '/refund-reason',
        text: "Thank you for reaching out to us regarding your refund request. In order to assist you effectively, could you please provide us with more details about the reason for your refund request? Your feedback is valuable to us, and understanding your specific concerns will help us address any issues and improve our service. \n\nOnce we have more information from you, we'll be able to proceed with the refund process accordingly. \n\nThank you for your cooperation. Feel free to share any relevant information that will help us assist you better."
    },
    {
        label: '/email-update',
        text: "I'm sorry, but currently, there isn't an option to change the email associated with your BlockerX account within the app. The email you used to sign up for BlockerX is typically used as your account identifier and cannot be modified. \n\nIf you need to use a different email address, you would need to create a new account using that email. However, please keep in mind that this would be a separate account, and any previous data or settings associated with your old email would not transfer to the new account. \n\nIf you have any specific concerns or issues related to your email or account, please provide more details, and we'll do our best to assist you further."
    },
    {
        label: '/windows-app-suggestions',
        text: 'Dear user,\n\nThanks for reaching out to us. We apologize for the inconvenience caused to you.\n\nTo ensure that BlockerX continues to work effectively, we recommend using our Windows app in addition to the browser extension. The Windows app provides enhanced features and protection that can prevent uninstallation and block websites regardless of the web browser you use.\n\nYou can download the BlockerX Windows app by clicking on the following link: https://blockerx.onelink.me/oeB0/upsellingwapp. This will allow you to have better control over your browsing experience and ensure that the blocking features are not bypassed by simply installing a new web browser.\n\nThank you.'
    },
    {
        label: '/vpn-issue',
        text: "Dear user,\n\nWe apologize for the inconvenience you are facing regarding BlockerX's VPN. To resolve these issues, kindly try the following steps:\n\n1. Restart Your Device: A simple restart can sometimes resolve minor connectivity issues. Try restarting your device and see if that helps.\n\n2. Uninstall and Reinstall BlockerX: If the issues persist, you might consider uninstalling and reinstalling the BlockerX app. This can help ensure you have the latest versions and a fresh installation.\n\nIf the issues persist after trying these steps, don't hesitate to contact us again for further assistance. We're here to help.\n\nThank you for being so patient."
    },
    {
        label: '/premium-not-active',
        text: "Dear user,\n\nThanks for reaching out to us. We apologize for the inconvenience.\n\nWe were unable to locate a premium subscription on your account. You might have entered an incorrect email while purchasing BlockerX's subscription.\n\nTo fix this, kindly provide us with the last four digits of the card you used to purchase the subscription, and we will do our best to locate your account.\n\nThank you."
    },
    {
        label: '/email',
        text: 'Dear user,\n\nThanks for reaching out to us. We apologize for the inconvenience.\n\nIn order to assist you further, kindly provide us with the email ID that you used to sign up for the BlockerX app. With this information, we will be able to locate your account and provide you with the necessary support.\n\nThank you.'
    },
    {
        label: '/anyone-with-link',
        text: "Dear user,\n\nThank you for reaching out and sharing a screen recording with us. However, it seems that the permission settings for the Google Drive link are not set to 'Anyone with the link'. As a result, we are currently unable to review the content.\n\nTo ensure a swift resolution, kindly update the sharing settings to 'Anyone with the link' for the provided Google Drive file. This will allow our team to access and analyze the screen recording effectively.\n\nOnce the permissions are adjusted, please reply to this email or share the updated link, and we will promptly assist you further.\n\nThank you for your cooperation."
    },
    {
        label: '/streak-reset',
        text: 'Dear user,\n\nThank you for reaching out to BlockerX Support.\n\nTo view or manage your streak data, please visit your account portal by clicking on the following link: https://accounts.blockerx.net/signin.\n\nIf you have any further questions or encounter issues, feel free to let us know.\n\n Thank you.'
    },
    {
        label: '/adding-accountability-partner-ios',
        text: "Dear user,\n\nThanks for reaching out to us. We apologize for the inconvenience.\n\n\nTo add a real person as a buddy (accountability partner), please follow these steps:\n\n1) On the 'Your buddy' card on the blocking screen, Click on 'edit'\n2) In the next screen, click on 'remove buddy'\n3) You can now type long sentences or bypass this right away by paying a fee\n4) Once the above step is complete, your existing buddy (long sentences) will be removed.\n5) You can now add a new accountability partner by entering their email address.\n\n\nHope this clarifies.\n\nThank you."
    },
    {
        label: '/tech-review',
        text: 'Dear user,\n\nThanks for reaching out to us. We apologize for the inconvenience. Please be assured that we will do every thing possible from our side to resolve this issue at the earliest.\n\nWe have passed this issue to our engineers and this is under investigation. We will keep you posted on the progress.\n\nThank you.'
    },
    {
        label: '/premium-not-found',
        text: "Dear user,\n\nThanks for reaching out to us. We apologize for the inconvenience.\n\nWe're unable to find any premium subscriptions associated with [email]. Please share the email address which you used to purchase premium or alternatively share the purchase receipt which you would have received over email (from Apple/Google/Stripe) so we can locate your account.\n\nLook forward to hearing from you.\n\nThank you."
    },
    {
        label: '/account-delete-confirmation',
        text: 'Dear user,\n\nThanks for reaching out to us.\n\nWe have now deleted your account as per your request.\n\nThank you.'
    },
    {
        label: '/refund-confirmation',
        text: 'Dear user,\n\nThanks for reaching out to us. We apologize for the inconvenience.\n\nA refund has been issued as per your request and it will reflect in your account in 5 to 10 business days.\n\nThank you.'
    },
    {
        label: '/device-restart',
        text: 'Dear user,\n\nThanks for reaching out to us. We apologize for the inconvenience.\n\nPlease note that when the device restarts, it will take a few seconds for all the background services to start. Until then the blocking might not work as usual. This is an Android operating system technical limitation and making any improvements is technically not possible and beyond our control.\n\nHowever, after a few seconds, the blocking will start and resume as usual. \n\nHope this clarifies.\n\nThank you.'
    },
    {
        label: '/premium-not-found-second',
        text: 'Thank you for reaching out, and we apologize for any inconvenience.\n\nTo ensure a swift and accurate resolution for your request, kindly provide the email address used for the premium purchase.This information is crucial in precisely locating your account details.\n\nAlternatively, sharing the purchase receipt from Apple/Google/Stripe, received via email, aids us in expediting the process.\n\nThese details are pivotal for verifying the transaction and guaranteeing the security of your account.\n\nYour understanding and cooperation are greatly appreciated, as they enable us to serve you more effectively.\n\nThank you.'
    },
    {
        label: '/non-genuine-app-version',
        text: 'Dear user,\n\n\nWe are unable to assist users with non-genuine versions of BlockerX. For help and support, please ensure you are using a legitimate and licensed version of the product. Feel free to reach out if you have any questions about the official version.\n\nThank you.'
    },
    {
        label: '/monthly-billing-issue',
        text: "Dear user,\n\nThank you for reaching out, and we apologize for any confusion regarding the billing.\n\nWe'd like to clarify that our pricing is displayed monthly to help users better understand the costs, but the actual billing occurs annually. Most apps on android and ios do this and it is a common practice.\n\nWe understand that this might have caused some misunderstanding, and we kindly recommend paying extra attention to future in-app purchases to avoid any similar situations. Unfortunately, all purchases are considered final, and we regret to inform you that we are unable to process a refund in this case. However, if you face any issue with the app, reach out to us and we will assist you.\n\nThank you for your understanding."
    },
    {
        label: '/user-privacy-policy',
        text: 'Dear user,\n\nThanks for reaching out to us.\n\nRest assured, BlockerX does not store any browsing  data, either on the device or on our servers. The blocking happens real time based on the words present on the screen and none of the information is stored with us. Hope this clarifies.\n\nIf you have any further concerns or questions, please feel free to reach out.\n\nThank you.'
    }
]

exports.cannedResponses = [
    'This is canned response example',
    "Dear user,\n\nThanks for reaching out to us. We apologize for the inconvenience. We'd like to inform you that we won't be able to cancel subscriptions for iOS as the developer doesn't get access to any of the order details of a user's subscription: https://developer.apple.com/forums/thread/123329 \n\nWe kindly request you to cancel the subscription by following these steps: https://support.apple.com/en-in/HT202039 and request a refund via this link: https://support.apple.com/en-in/HT204084",
    'Hey there,\nJust following up to check if you received our previous email.',
    "Dear user, Thank you for reaching out to us. \n\nWe apologize for the inconvenience you have experienced. We fully understand the importance of resolving the issue you're facing, and we are committed to providing you with the best possible assistance. To assist us in troubleshooting and resolving the problem more effectively, we kindly request that you provide us with additional details. \n\nCould you please provide us with more information about the issue you're encountering? A screen recording would be greatly helpful in understanding the problem accurately. This will enable us to provide you with a more tailored and efficient solution. We look forward to receiving the requested information from you so that we can promptly address and resolve the problem. \n\nThank you for your understanding and patience.",
    "Dear user, Thanks for reaching out to us. We apologize for the inconvenience. \n\nIf you are seeking a refund or wish to cancel your subscription, please visit your account's dashboard to check eligibility and initiate the process. You can access your account at https://accounts.blockerx.net/signin. If you have any difficulties or further questions, feel free to let us know. \nThank you.",
    "Dear user,\n\nThanks for reaching out to us. We apologize for the inconvenience. We have reviewed your account and it appears that you are currently using the Google Play Store version of BlockerX. We'd like to inform you that due to the stringent policies set by the Play Store, certain features of BlockerX, including the Prevent Uninstall feature, may not function as intended.\n\n To fully utilize all the advanced functions of BlockerX, we recommend downloading the APK version of the app. You can do so by following this link: https://blockerx.onelink.me/oeB0/uninstallemail. This version provides you with access to all features, including those that might be restricted in the Play Store version.\n\nOnce you've installed the APK version, you should be able to take advantage of the complete functionality of BlockerX. If you have any further questions or concerns, please don't hesitate to contact us. We're here to help!\n Thank you.",
    "Dear user,\n\nThanks for reaching out to us. We apologize for the inconvenience. \nTo manage your accountability partner or check its current status, please visit your BlockerX accounts page by clicking on the following link: https://accounts.blockerx.net/signin.\n\nFrom there, you'll be able to access all the necessary tools to modify or review your accountability partner settings. If you encounter any difficulties or have additional questions, please feel free to get in touch with us. We're here to assist you!\n\nThank you.",
    "Dear user,\n\nThanks for reaching out to us. We apologize for the inconvenience.\n\nPlease note that we are unable to turn off or edit a user's account settings or blocklist as our users have full control over their own accounts. If you have any concerns or questions regarding your account settings or blocklist, we recommend accessing your account dashboard for any necessary adjustments at https://accounts.blockerx.net/signin. \n\nThank you.",
    "Dear user,\n\nThanks for reaching out to us. We apologize for the inconvenience.\n\nWe have reviewed your account and it appears that you are currently using the Google Play Store version of BlockerX. We'd like to inform you that due to the stringent policies set by the Play Store, certain features of BlockerX, including the Accountability Partner feature, may not function as intended.\n\n To fully utilize all the advanced functions of BlockerX, we recommend downloading the APK version of the app. You can do so by following this link: https://blockerx.onelink.me/oeB0/uninstallemail. This version provides you with access to all features, including those that might be restricted in the Play Store version.\n  Once you've installed the APK version, you should be able to take advantage of the complete functionality of BlockerX. If you have any further questions or concerns, please don't hesitate to contact us. We're here to help!\n\nThank you."
]

exports.getReplyFromCannedResponseId = (key) => {
    var result = ''
    this.cannedResponseAutocomplete.forEach((object) => {
        if (object['label'] === key) {
            result = object['text']
        }
    })
    return result
}
