import React, { useEffect, useState } from 'react'
import { signOut } from 'firebase/auth'
import MuiAlert from '@mui/material/Alert'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import ReactGA from 'react-ga'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Input, Snackbar, Stack } from '@mui/material'
import Button from '@mui/material/Button'
import { convertMillisecondsToDate, showTimeInAgoFormat } from '../../Utils/time'
import secureLocalStorage from 'react-secure-storage'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

function Login() {
    let navigate = useNavigate()
    const [isAuth, setIsAuth] = useState(secureLocalStorage.getItem('isAuth'))
    const [email, setEmail] = useState(secureLocalStorage.getItem('email'))
    const [fullname, setFullname] = useState(secureLocalStorage.getItem('fullname'))
    const [createdAt, setCreatedAt] = useState(secureLocalStorage.getItem('createdAt'))
    const [lastActive, setLastActive] = useState(secureLocalStorage.getItem('lastActive'))

    const [inputEmail, setInputEmail] = useState('')
    const [inputPassword, setInputPassword] = useState('')

    const [error, setError] = useState('Error')

    const clearAllFields = () => {
        setInputEmail('')
        setInputPassword('')
    }

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const signInCustom = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: inputEmail, password: inputPassword })
        }

        fetch('https://dev.blockerx.net/api/dashboard/signIn', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    secureLocalStorage.setItem('uid', data.data._id)
                    secureLocalStorage.setItem('email', inputEmail)
                    secureLocalStorage.setItem('token', data.data.token)
                    secureLocalStorage.setItem('fullname', data.data.fullname)
                    secureLocalStorage.setItem('createdAt', data.data.createdOn)
                    secureLocalStorage.setItem('lastActive', data.data.lastActive)
                    secureLocalStorage.setItem('isAuth', true)
                    setFullname(data.data.fullname)
                    setCreatedAt(data.data.createdAt)
                    setLastActive(data.data.lastActive)
                    navigate('/')
                } else if (data.status === 400) {
                    setError(data.data.info)
                    setOpenErrorAlert(true)
                    clearAllFields()
                    secureLocalStorage.setItem('isAuth', false)
                } else {
                    setOpenErrorAlert(true)
                    clearAllFields()
                    secureLocalStorage.setItem('isAuth', false)
                }
            })
            .catch((error) => {
                secureLocalStorage.setItem('isAuth', false)
            })
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])

    const resetPasswordHandler = () => {
        navigate('/resetPassword')
    }

    const logoutHandler = () => {
        secureLocalStorage.clear()
        window.location.reload()
        // navigate('/')
    }

    return (
        <div>
            {isAuth === true ? (
                <div>
                    <Box sx={{ flexGrow: 2 }}>
                        <Grid align='center' justify='center' spacing={2}>
                            <Grid item lg={4} md={6} sm={12} className='pt-5 pb-5'>
                                <p className='text-4xl mt-5'>
                                    <b>User Profile</b>
                                </p>
                            </Grid>

                            <Table
                                sx={{
                                    width: 500,
                                    marginTop: 3,
                                    border: '1px solid #D3D3D3'
                                }}
                                aria-label='customized table'>
                                <TableBody>
                                    <StyledTableRow key='11123'>
                                        <StyledTableCell component='th' scope='row' align='right'>
                                            Name
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            <b>{fullname}</b>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='1233434'>
                                        <StyledTableCell component='th' scope='row' align='right'>
                                            Email
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            <b>{email}</b>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='1232'>
                                        <StyledTableCell component='th' scope='row' align='right'>
                                            Last active
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            <b>{showTimeInAgoFormat(Number(lastActive))}</b>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='1233'>
                                        <StyledTableCell component='th' scope='row' align='right'>
                                            Created At
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            <b>{convertMillisecondsToDate(Number(createdAt))}</b>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='12343'>
                                        <StyledTableCell component='th' scope='row' align='right'>
                                            Role
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            <b>User</b>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>

                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <Button className='h-12' variant='contained' color='success' sx={{ width: '500px' }} onClick={resetPasswordHandler}>
                                    Reset Password
                                </Button>
                            </Grid>

                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <Button className='h-12' variant='contained' color='error' sx={{ width: '500px' }} onClick={logoutHandler}>
                                    Sign Out
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            ) : (
                <div>
                    <Box sx={{ flexGrow: 2 }}>
                        <Grid align='center' justify='center' spacing={2}>
                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <h1 className='header-top mt-5'>Sign In</h1>
                            </Grid>

                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <Input
                                    disablePortal
                                    placeholder='Enter your email'
                                    value={inputEmail}
                                    id='combo-box-demo'
                                    sx={{ width: '60%' }}
                                    onChange={(event, item) => {
                                        setInputEmail(event.target.value)
                                    }}
                                />
                            </Grid>

                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <Input
                                    type='password'
                                    disablePortal
                                    placeholder='Enter your password'
                                    value={inputPassword}
                                    id='combo-box-demo-2'
                                    sx={{ width: '60%' }}
                                    onChange={(event, item) => {
                                        setInputPassword(event.target.value)
                                    }}
                                />
                            </Grid>

                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <Button
                                    className='h-12'
                                    variant='contained'
                                    sx={{
                                        width: '60%',
                                        backgroundColor: '#3C4048'
                                    }}
                                    onClick={signInCustom}>
                                    Sign In
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            )}
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                        Success!!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>
            </Stack>
        </div>
    )
}

export default Login
