import secureLocalStorage from 'react-secure-storage'
import './RedeemCoinLogs.css'
import React, { useEffect, useState } from 'react'
import { BarChart } from '@mui/x-charts/BarChart'
import { Button, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { formatDateToYYYYMMDD, showTimeInAgoFormat } from '../../Utils/time'
import { capitalizeFirstLetter } from '../../Utils/string'
import { Star } from '@mui/icons-material'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#4F709C',
        color: theme.palette.common.white,
        fontSize: 16
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

function RedeemCoinLogs() {
    const [apiCalled, setApiCalled] = useState(false)
    const [logsList, setLogsList] = useState([])

    const [campaignsList, setCampaignsList] = useState([])

    const [alexArray, setAlexArray] = useState([0, 0, 0, 0, 0])
    const [simonArray, setSimonArray] = useState([0, 0, 0, 0, 0])
    const [tedArray, setTedArray] = useState([0, 0, 0, 0, 0])
    const [naomiArray, setNaomiArray] = useState([0, 0, 0, 0, 0])

    const [currentAgentName, setCurrentAgentName] = useState('All Agents')
    const [currentAgentId, setCurrentAgentId] = useState('all')
    const [startDate, setStartDate] = useState(1709058600000)
    const [startDateInputValue, setStartDateInputValue] = useState('2024-02-28')
    const [endDate, setEndDate] = useState(new Date().getTime())
    const [endDateInputValue, setEndDateInputValue] = useState(formatDateToYYYYMMDD(new Date().getTime()))
    const dashboardUid = secureLocalStorage.getItem('uid')

    const processFeedbackData = (data, agentName) => {
        const agentData = data.filter((entry) => entry.agent === agentName)
        const resultArray = Array.from({ length: 5 }, (_, index) => {
            const feedbackEntry = agentData.find((entry) => entry.stars === index + 1)
            return {
                stars: index + 1,
                count: feedbackEntry ? feedbackEntry.count : 0
            }
        })

        const countsArray = Array.from({ length: 5 }, (_, index) => {
            const feedbackEntry = resultArray.find((entry) => entry.stars === index + 1)
            return feedbackEntry ? feedbackEntry.count : 0
        })

        return countsArray
    }

    const getFeedbackRatingStats = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch(`https://events.blockerx.net/api/coins/redeem/logs?startDate=${startDate}&endDate=${endDate}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setApiCalled(true)
                    setLogsList(data.data.logs)
                } else {
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const openEachTicketContainer = (ticketId) => {
        window.open(`/customer-tickets/${ticketId}`, '_blank')
    }

    useEffect(() => {
        if (!apiCalled) {
            getFeedbackRatingStats()
        }
    })
    return (
        <>
            <div>
                <p className='text-center text-4xl font-bold mt-12'>Redeem Logs</p>
            </div>
            <div className='ticketfeedback__data__container'>
                <TextField
                    label='Start Date'
                    type='date'
                    value={startDateInputValue}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '14%', margin: '1%' }}
                    onChange={(event, item) => {
                        setStartDateInputValue(event.target.value)
                        const myDate = event.target.value.split('-')
                        const newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]).getTime()
                        setStartDate(newDate)
                    }}
                />

                <TextField
                    label='End Date'
                    id='combo-box-demo'
                    type='date'
                    value={endDateInputValue}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '14%', margin: '1%' }}
                    onChange={(event, item) => {
                        setEndDateInputValue(event.target.value)
                        const myDate = event.target.value.split('-')
                        const newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]).getTime()
                        setEndDate(newDate)
                    }}
                />

                <Button className='h-12' sx={{ width: '10%' }} onClick={() => getFeedbackRatingStats()} variant='contained'>
                    Apply
                </Button>
            </div>
            <div className='campaign__list__container__feedback pb-32 pt-16'>
                <TableContainer component={Paper} className=''>
                    <Table aria-label='customized table'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>User ID</StyledTableCell>
                                <StyledTableCell align='center'>Actionn</StyledTableCell>
                                <StyledTableCell align='center'>Coins</StyledTableCell>
                                <StyledTableCell align='center'>Stars</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {logsList.map((row) => (
                                <StyledTableRow key={row._id}>
                                    <StyledTableCell className='cursor-pointer hover:underline' scope='row'>
                                        {row.uid}
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>{row.action}</StyledTableCell>
                                    <StyledTableCell align='center'>{row.coin}</StyledTableCell>
                                    <StyledTableCell align='center'> {showTimeInAgoFormat(Number(row.time))}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    )
}

export default RedeemCoinLogs
