import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { TextField, Button, Box, List, ListItem, ListItemText, Paper } from '@mui/material';

import auth, { provider } from './blockerxConfig';
import { signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function ChatbotPage() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)
  const [loggedIn, setLoggedIn] = useState(false)
  const [uid, setUid] = useState(null)
  const [email, setEmail] = useState(null)
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');

  const handleSendMessage = async () => {
    const newUserMessage = { author: 'User', text: userInput };
    // add loading
    setLoading(true)
    setMessages([...messages, newUserMessage]);
    try {
      const response = await axios.post('https://dev.blockerx.net/api/ai-friend/v1/converse', {
        uid: uid,
        query: userInput.trim()
      })
      const botMessage = { author: 'Tina', text: response.data.data.response }
      setMessages(messages => [...messages, botMessage]);
    } catch (error) {
      console.error('Error sending message:', error);
    }
    setUserInput('')
    setLoading(false)
  }

  function signInWithGoogle() {
    return new Promise((resolve, reject) => {
      signInWithPopup(auth, provider)
        .then((result) => {
          console.log({ result })
          setUid(result.user.uid)
          setEmail(result.user.email)
          axios.post('https://dev.blockerx.net/api/ai-friend/v1/user', {
            uid: result.user.uid,
            email: result.user.email,
            username: "sample-name",
            gender: "male",
            age: '31',
            purpose: "Sample purpose",
            botGender: "female",
            interactionType: "text",
            provider: "google"
          })
            .then((response2) => {
              console.log({ response2 })
              axios.get(`https://dev.blockerx.net/api/ai-friend/v1/chat-history?uid=${result.user.uid}`)
                .then((response3) => {
                  if (response3.data.data.history && response3.data.data.history.length) {
                    const CHAT_HISTORY = response3.data.data.history
                    const INITIAL_CHAT = []
                    CHAT_HISTORY.map((singleChat) => {
                      console.log('Single chat', singleChat)
                      if (singleChat.role === 'user') {
                        INITIAL_CHAT.push({
                          author: 'User',
                          text: singleChat.message
                        })
                      } else {
                        INITIAL_CHAT.push({
                          author: 'Tina',
                          text: singleChat.message
                        })
                      }
                    })
                    setMessages(INITIAL_CHAT)
                  }
                  resolve({
                    status: 200
                  })
                })
                .catch((error3) => {
                  resolve({
                    status: 404
                  })
                })
            })
            .catch((error2) => {
              console.log({ error2 })
              axios.get(`https://dev.blockerx.net/api/ai-friend/v1/chat-history?uid=${result.user.uid}`)
                .then((response3) => {
                  if (response3.data.data.history && response3.data.data.history.length) {
                    const CHAT_HISTORY = response3.data.data.history
                    const INITIAL_CHAT = []
                    CHAT_HISTORY.map((singleChat) => {
                      console.log('Single chat', singleChat)
                      if (singleChat.role === 'user') {
                        INITIAL_CHAT.push({
                          author: 'User',
                          text: singleChat.message
                        })
                      } else {
                        INITIAL_CHAT.push({
                          author: 'Tina',
                          text: singleChat.message
                        })
                      }
                    })
                    setMessages(INITIAL_CHAT)
                  }
                  resolve({
                    status: 200
                  })
                })
                .catch((error3) => {
                  resolve({
                    status: 404
                  })
                })
            });
        })
        .catch((error) => {
          resolve({
            status: 404
          })
        })
    })
  }

  const googleSignIn = async () => {
    setLoading(true)
    const GOOGLE_RES = await signInWithGoogle()
    if (GOOGLE_RES && GOOGLE_RES.status === 200) {
      setLoggedIn(true)
      setLoading(false)
      // alert('Congratulation! Your account has been successfully created')
      // navigate("/")
    } else {
      alert('Something went wrong')
    }
  }

  useEffect(() => {
    setLoading(false)
  }, [])


  if (loading) {
    return (
      <Box sx={{ display: 'flex', width: '100%', paddingTop: '20vh', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )
  } else {
    if (loggedIn) {
      return (
        <Container maxWidth="sm">
          <h1>Tina: Your AI Assistant</h1>
          <Box sx={{ margin: 2 }}>
            <Paper style={{ height: '500px', overflow: 'auto' }}>
              <List>
                {messages.map((msg, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={msg.text} secondary={msg.author} />
                  </ListItem>
                ))}
              </List>
            </Paper>
            <TextField
              fullWidth
              label="Type your message here..."
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyPress={(e) => { if (e.key === 'Enter') handleSendMessage(); }}
            />
            <Button variant="contained" color="primary" onClick={handleSendMessage}>
              Send
            </Button>
          </Box>
        </Container>
      )
    } else {
      return (
        <Box sx={{ display: 'flex', width: '100%', paddingTop: '20vh', justifyContent: 'center' }}>
          <Button variant="contained" onClick={googleSignIn}>Sign in with Google</Button>
        </Box>
      );
    }
  }
}

export default ChatbotPage;
