import * as React from 'react'
import secureLocalStorage from 'react-secure-storage'
import { useEffect, useState } from 'react'
import { Autocomplete, Button, Grid, IconButton, Input, Snackbar, Stack, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Box } from '@mui/system'
import MuiAlert from '@mui/material/Alert'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import ReactGA from 'react-ga'
import { useLocation, useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import { BASE_URL } from '../../api/Constants'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

function convertMillisecondsToDate(ms) {
    return new Date(ms).toString()
}

const Blocklist = () => {
    const [apiCalled, setApiCalled] = useState(false)
    const [blockList, setBlockList] = useState([])
    const [inputUid, setInputUid] = useState('')
    const [blockedName, setBlockedName] = useState('website.com')

    let navigate = useNavigate()
    const token = secureLocalStorage.getItem('token')
    const dashboardUid = secureLocalStorage.getItem('uid')

    const [blockedDomains, setBlockedDomains] = useState([])
    const [deleteDomainId, setDeleteDomainId] = useState('')

    const [openAddDialog, setOpenAddDialog] = useState(false) // For Add to Blocklist popup
    const [newDomainData, setNewDomainData] = useState({ uid: '', category: 'keyword', type: 'block', name: '', packageName: '' })

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const resetAllFields = () => {
        setApiCalled(false)
        setBlockList([])
        setInputUid('')
    }

    const getBlockedListOfUser = (UID) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
            body: JSON.stringify({ uid: UID, dashboardUid: dashboardUid })
        }

        fetch('https://dev.blockerx.net/api/dashboard/blocklist', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (data.status === 200) {
                    setApiCalled(true)
                    const array = data.data.reverse()
                    setBlockedDomains(array)
                } else {
                    setOpenErrorAlert(true)
                    resetAllFields()
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    const deleteDomainHandler = (id) => {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
            body: JSON.stringify({ blockId: id, uid: inputUid, dashboardUid: dashboardUid })
        }

        fetch('https://dev.blockerx.net/api/dashboard/blocklist', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    const array = data.data.userblocklist.reverse()
                    setBlockedDomains(array)
                } else if (data.status === 401) {
                    secureLocalStorage.clear()
                    navigate('/tokenExpired')
                } else if (data.status === 403) {
                    secureLocalStorage.clear()
                    navigate('/forbidden')
                } else {
                    setOpenErrorAlert(true)
                    resetAllFields()
                }
            })
    }

    const addToBlocklist = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
            body: JSON.stringify({
                ...newDomainData,
                uid: inputUid,
                action: 'add'
            })
        }

        fetch(`${BASE_URL}/api/v1/wordAction`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 'success') {
                    setOpenSuccessAlert(true)
                    getBlockedListOfUser(inputUid)
                    setOpenAddDialog(false) // Close dialog
                } else {
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])

    const location = useLocation()
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const uid = queryParams.get('uid')
        if (uid) {
            setInputUid(uid)
            getBlockedListOfUser(uid)
        }
    }, [location.search])

    return (
        <Box sx={{ flexGrow: 2 }}>
            <Grid align='center' justify='center' spacing={2}>
                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <h1 className='header-top mt-5'> Block List</h1>
                </Grid>

                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <Input
                        disablePortal
                        placeholder='Enter uid'
                        id='combo-box-demo'
                        value={inputUid}
                        sx={{ width: '35%' }}
                        onChange={(event, item) => {
                            setInputUid(event.target.value)
                        }}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <Button className='h-12' variant='contained' sx={{ width: '35%' }} color='success' onClick={() => getBlockedListOfUser(inputUid)}>
                        Get Blocked List
                    </Button>
                </Grid>

                {apiCalled && inputUid && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenAddDialog(true)}
                        sx={{ mt: 2 }}
                    >
                        Add to Blocklist
                    </Button>
                )}

                {apiCalled && (
                    <Table sx={{ width: '90%', marginTop: 10, marginBottom: 30 }} aria-label='customized table'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>S.No</StyledTableCell>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell align='left'>Category</StyledTableCell>
                                <StyledTableCell align='left'>Type</StyledTableCell>
                                <StyledTableCell align='left'>Added on</StyledTableCell>
                                <StyledTableCell align='left'>Delete</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {blockedDomains.map((eachDomain, index) => (
                                <StyledTableRow key={eachDomain._id}>
                                    <StyledTableCell>{index + 1}</StyledTableCell>
                                    <StyledTableCell>{eachDomain.name}</StyledTableCell>
                                    <StyledTableCell>{eachDomain.category}</StyledTableCell>
                                    <StyledTableCell>{eachDomain.type}</StyledTableCell>
                                    <StyledTableCell>{convertMillisecondsToDate(eachDomain.time)}</StyledTableCell>
                                    <StyledTableCell>
                                        <IconButton onClick={() => deleteDomainHandler(eachDomain._id)}>
                                            <DeleteIcon style={{ color: '#d11a2a' }} />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </Grid>

            {/* Add to Blocklist Dialog */}
            <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
                <DialogTitle>Add to Blocklist</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Name"
                        value={newDomainData.name}
                        onChange={(e) => setNewDomainData({ ...newDomainData, name: e.target.value })}
                        fullWidth
                        margin="dense"
                    />
                    <Autocomplete
                        options={['keyword', 'website', 'app']}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => <TextField {...params} label="Category" />}
                        onChange={(event, newValue) => setNewDomainData({ ...newDomainData, category: newValue })}
                        fullWidth
                        margin="dense"
                    />
                    <Autocomplete
                        options={['protected', 'block', 'whitelist', 'newInstall', 'inAppBrowser']}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => <TextField {...params} label="Type" />}
                        onChange={(event, newValue) => setNewDomainData({ ...newDomainData, type: newValue })}
                        fullWidth
                        margin="dense"
                    />
                    {/* Package Name Field - Shown only if category is "app" */}
                    {newDomainData.category === 'app' && (
                        <TextField
                            label="Package Name"
                            value={newDomainData.packageName}
                            onChange={(e) => setNewDomainData({ ...newDomainData, packageName: e.target.value })}
                            fullWidth
                            margin="dense"
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenAddDialog(false)}>Cancel</Button>
                    <Button onClick={addToBlocklist} color="primary">Add</Button>
                </DialogActions>
            </Dialog>

            {/* Success and Error Alerts */}
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity='success'>Success!!!</Alert>
                </Snackbar>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity='error'>Error!!!</Alert>
                </Snackbar>
            </Stack>
        </Box>
    )
}

export default Blocklist
