import { Box, Button, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import './AndroidBlockStats.css'
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage'

const AndroidBlockStats = () => {

    const navigate = useNavigate()
    const [currentSortParam, setCurrentSortParam] = useState('last_24_hrs')
    const [statsList, setStatsList] = useState([])

    const handleSortFilter = (sortBy, selected) => {
        setCurrentSortParam(sortBy)
    }

    const DynamicButton = (props) => {
        return <Button color='primary' variant={props.variant} sx={{ marginRight: '5px' }} onClick={() => handleSortFilter(props.sortParam, props.state)}>{props.content}</Button>;
    }

    const token = secureLocalStorage.getItem("token");
    const dashboardUid = secureLocalStorage.getItem("uid")

    const getCurrentStartTime = (param) => {
        if (param === 'last_24_hrs') {
            return new Date().getTime() - 86400000
        } else if (param === 'last_3_days') {
            return new Date().getTime() - 3 * 86400000
        } else if (param === 'last_7_days') {
            return new Date().getTime() - 7 * 86400000
        } else {
            return new Date().getTime() - 86400000
        }
    }

    const GmailUsersHandler = () => {
        setStatsList([])
        const startTime = getCurrentStartTime(currentSortParam)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "startTime": startTime, "dashboardUid": dashboardUid })
        };
        fetch('https://dev.blockerx.net/api/dashboard/android/stats/block', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === 200) {
                    setStatsList(data.data.blocklist)
                } else if (data.status === 401) {
                    secureLocalStorage.clear();
                    navigate("/tokenExpired");
                } else if (data.status === 403) {
                    secureLocalStorage.clear();
                    navigate("/forbidden");
                } else {
                    secureLocalStorage.clear();
                    navigate("/tokenExpired");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        GmailUsersHandler()
    }, [currentSortParam])

    return (
        <>
            <h1 className='snippets__header my-5'>Android Block Stats</h1>
            <div className='ml-5 mb-5 filter__bar__companies'>
                <div>
                    {/* <span className='mr-2'>Sort By</span> */}
                    {
                        currentSortParam === 'last_24_hrs' && <>
                            <DynamicButton variant='contained' content='Last 24 hours' sortParam='last_24_hrs' state={true} />
                            <DynamicButton variant='outlined' content='Last 3 Days' sortParam='last_3_days' state={false} />
                            <DynamicButton variant='outlined' content='Last 7 Days' sortParam='last_7_days' state={false} />
                        </>
                    }
                    {
                        currentSortParam === 'last_3_days' && <>
                            <DynamicButton variant='outlined' content='Last 24 hours' sortParam='last_24_hrs' state={false} />
                            <DynamicButton variant='contained' content='Last 3 Days' sortParam='last_3_days' state={true} />
                            <DynamicButton variant='outlined' content='Last 7 Days' sortParam='last_7_days' state={false} />
                        </>
                    }
                    {
                        currentSortParam === 'last_7_days' && <>
                            <DynamicButton variant='outlined' content='Last 24 hours' sortParam='last_24_hrs' state={false} />
                            <DynamicButton variant='outlined' content='Last 3 Days' sortParam='last_3_days' state={false} />
                            <DynamicButton variant='contained' content='Last 7 Days' sortParam='last_7_days' state={true} />
                        </>
                    }

                </div>
            </div>
            <div class="main-div-snippets mb-20">
                {
                    statsList.length > 0 && statsList.map((item) => {
                        return (
                            <div class="card-list-snippets">
                                <article class="card-snippets">
                                    <div>
                                        <h2 className='font-semibold my-4'>{item.name} <span className='font-normal'>in </span> {item.app}</h2>
                                    </div>
                                    <div class="card-footer-snippets">
                                        <div class="card-meta-snippets card-meta--date">
                                            Count : {item.count}
                                        </div>
                                    </div>
                                </article>
                            </div>
                        )
                    })
                }
                {
                    statsList.length === 0 && <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '300px' }}>
                        <CircularProgress size="7rem" color="grey" />
                    </Box>
                }
            </div>
        </>
    )
}

export default AndroidBlockStats