import './App.css'
import ReactGA from 'react-ga'
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom'
import Homepage from './components/Homepage/Homepage'
import Userinfo from './components/User/Userinfo/Userinfo'
import Header from './components/Header/Header'
import PremiumPlan from './components/PremiumPlan/PremiumPlan'
import Login from './components/Auth/Login'
import Partner from './components/Partner/Partner'
import Firebase from './components/Firebase/Firebase'
import Streak from './components/Streak/Streak'
import Mongo from './components/Mongo/Mongo'
import TokenExpired from './components/Auth/TokenExpired'
import Peers from './components/Peers/Peers'
import DashboardStats from './components/DashboardStats/DashboardStats'
import Blocklist from './components/Blocklist/Blocklist'
import Stripe from './components/Stripe/Stripe'
import Community from './components/Community/Community'
import CommunityUser from './components/CommunityUser/CommunityUser'
import Coins from './components/Coins/Coins'
import BlockWindow from './components/BlockWindow/BlockWindow'
import EditUser from './components/User/EditUser/EditUser'
import FindUser from './components/User/FindUser/FindUser'
import Forbidden from './components/Auth/Forbidden'
import DashboardActivity from './components/DashboardActivity/DashboardActivity'
import Migrate from './components/Migrate/Migrate'
import GmailUsers from './components/User/GmailUsers/GmailUsers'
import AndroidBlockStats from './components/AndroidBlockStats/AndroidBlockStats'
import ReplyToReviews from './components/ReplyToReviews/ReplyToReviews'
import ViewTickets from './components/Tickets/ViewTickets/ViewTickets'
import ViewEachTicket from './components/Tickets/ViewEachTicket/ViewEachTicket'
import PushNotifications from './components/PushNotifications/PushNotifications'
import ViewClosedTickets from './components/Tickets/ViewClosedTickets/ViewClosedTickets'
import UserTicketHistory from './components/Tickets/UserTicketHistory/UserTicketHistory'
import ViewTicketsRevamped from './components/Tickets/ViewTicketsRevamped/ViewTicketsRevamped'
import Campaigns from './components/Campaigns/Campaigns'
import CampaignsList from './components/CampaignsList/CampaignsList'
import TicketFeedback from './components/Tickets/TicketFeedback/TicketFeedback'
import isAuthenticated from './auth/AuthenticateDashboardUser'
import UnsubscriberMail from './components/Mail/UnsubscribeMail'
import ZenzeUserInfo from './components/Zenze/ZenzeUserInfo/ZenzeUserInfo'
import UserInfoPage from './components/User/UserInfoPage/UserInfoPage'
import Feedback from './components/Feeback/Feedback'
import DeveloperTickets from './components/Tickets/DeveloperTickets/DeveloperTickets'
import DeveloperProjects from './components/DeveloperProjects/DeveloperProjects'
import ZenzeApiLogs from './components/Zenze/ZenzeApiLogs/ZenzeApiLogs'
import MailLogs from './components/MailLogs/MailLogs'
import CustomMail from './components/CustomMail/CustomMail'
import RedeemCoinLogs from './components/RedeemCoinLogs/RedeemCoinLogs'
import TicketLogs from './components/Tickets/TicketLogs/TicketLogs'
import BlockerxProWhitelist from './components/BlockerxProWhitelist/BlockerxProWhitelist'
import PremiumPush from './components/PremiumPush/PremiumPush'
import ChatbotLogs from './components/ChatbotLogs/ChatbotLogs'
import ZenzePremium from './components/Zenze/ZenzePremium/ZenzePremium'
import StripeRevenuePage from './components/StripeRevenue/stripeRevenue'
import VpnBlocklist from './components/Blocklist/VpnBlocklist'
import ChatbotPage from './components/SampleChatbot/sampleChatbot'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ZenzeAnalysis from './components/Zenze/ZenzeAnalysis/ZenzeAnalysis'
import APKRestriction from './components/APKRestriction/APKRestriction'
import BlockedItems from './components/Blocklist/BlockedItems'
import CommonRestriction from './components/APKRestriction/CommonRestriction'
import ZiaUsers from './components/Zia/ZiaUsers'
import ZiaConversation from './components/Zia/ZiaConversation'
import ZenzeInstagramChallenge from './components/Zenze/ZenzeInstagramChallenge'
import ZenzeAppChallenges from './components/Zenze/ZenzeChallenges/ZenzeAppChallenges'
import UserActions from './components/User/UserActions/UserActions'

const TRACKING_ID = 'UA-246624590-1'
ReactGA.initialize(TRACKING_ID)

function App() {
    const PrivateWrapper = () => {
        return isAuthenticated() ? <Outlet /> : <Navigate to='/profile' />
    }
    return (
        <BrowserRouter>
            <div>
                <Header />
                <ToastContainer />
                <Routes>
                    <Route exact path='/profile' element={<Login />} />
                    <Route path='/tokenExpired' element={<TokenExpired />} />
                    <Route path='/forbidden' element={<Forbidden />} />
                    <Route path='/stripeRevenue' element={<StripeRevenuePage />} />
                    <Route path='/sampleChatbot' element={<ChatbotPage />} />
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/' element={<Homepage />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/activity' element={<DashboardActivity />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/user-actions' element={<UserActions />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/userinfo' element={<Userinfo />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/userinfo/:id' element={<UserInfoPage />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/zenze-userinfo' element={<ZenzeUserInfo />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/streak' element={<Streak />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/premiumplan' element={<PremiumPlan />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/firebase' element={<Firebase />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/partner' element={<Partner />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/mongo' element={<Mongo />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/findUser' element={<FindUser />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/stats' element={<DashboardStats />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/blocklist' element={<Blocklist />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/blocked-items' element={<BlockedItems />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/vpn-blocklist' element={<VpnBlocklist />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/stripe' element={<Stripe />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/chatbot-logs' element={<ChatbotLogs />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/coins' element={<Coins />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/editUser' element={<EditUser />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/community' element={<Community />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/community-user' element={<CommunityUser />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        {' '}
                        <Route path='/gmail-users' element={<GmailUsers />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/blockWindow' element={<BlockWindow />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/migrate' element={<Migrate />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/push-premium' element={<PremiumPush />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/push-zenze-premium' element={<ZenzePremium />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/reply-to-reviews' element={<ReplyToReviews />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/peers' element={<Peers />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/android-block-stats' element={<AndroidBlockStats />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/customer-tickets' element={<ViewTickets />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        {' '}
                        <Route path='/customer-tickets-revamped' element={<ViewTicketsRevamped />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/closed-customer-tickets' element={<ViewClosedTickets />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/customer-tickets/:id' element={<ViewEachTicket />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/tickets-feedback' element={<TicketFeedback />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='/customer-tickets/users/:id' element={<UserTicketHistory />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='push-notification' element={<PushNotifications />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='campaigns' element={<Campaigns />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='unsubscribe-mails' element={<UnsubscriberMail />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='campaigns-list' element={<CampaignsList />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='feedback' element={<Feedback />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='developer-tickets' element={<DeveloperTickets />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='developer-projects' element={<DeveloperProjects />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='zenze-logs' element={<ZenzeApiLogs />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='mail-logs' element={<MailLogs />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='redeem-coin-logs' element={<RedeemCoinLogs />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='custom-mail' element={<CustomMail />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='ticket-logs' element={<TicketLogs />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='blockerx-pro-whitelist' element={<BlockerxProWhitelist />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='zenze-analysis' element={<ZenzeAnalysis />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='restrict-apk' element={<APKRestriction />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='user-restriction' element={<CommonRestriction />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='zia-users' element={<ZiaUsers />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='zia-conversation' element={<ZiaConversation />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='zenze-instagram-challenge' element={<ZenzeInstagramChallenge />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path='zenze-app-challenges' element={<ZenzeAppChallenges />} />
                    </Route>
                </Routes>
            </div>
        </BrowserRouter>
    )
}

export default App
