import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './CampaignsList.css'
import { useState } from 'react';
import { useEffect } from 'react';
import { convertMillisecondsToDate, showTimeInAgoFormat } from '../../Utils/time';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4F709C",
        color: theme.palette.common.white,
        fontSize: 16
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function CampaignsList() {
    const [campaignsList, setCampaignsList] = useState([])
    const [apiCalled, setApiCalled] = useState(false)

    // Logs
    const [showingLogs, setShowingLogs] = useState(false)
    const [campaignLogs, setCampaignLogs] = useState([])

    const getCampaignsListHandler = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch('https://events.blockerx.net/api/events/campaigns/list', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setCampaignsList(data.data.campaigns)
                    setApiCalled(true)
                } else {

                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const getCampaignsLogHandler = (campaign_type, campaign_template) => {
        setShowingLogs(true)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "type": campaign_type, "template": campaign_template})
        };

        console.log('Req body : ', requestOptions)

        fetch('https://events.blockerx.net/api/campaigns/logs', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setCampaignLogs(data.data.logs)
                } else {

                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const getStatusOfCampaign = (date) => {
        try {
            const currentTime = new Date().getTime()
            if (date > currentTime) {
                return 'Running'
            } else {
                return 'Stopped'
            }
        } catch {
            return 'Stopped'
        }
    }

    useEffect(() => {
        if (!apiCalled) {
            getCampaignsListHandler()
        }
    })
    return (
        <>
            <h1 className='text-center text-4xl font-bold my-6'> User Campaigns</h1>
            {
                (showingLogs !== true) && <div className='campaign__list__container'>
                <TableContainer component={Paper} className=''>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Campaign Name</StyledTableCell>
                                <StyledTableCell align="center">Status</StyledTableCell>
                                <StyledTableCell align="center">Type</StyledTableCell>
                                <StyledTableCell align="center">Started On</StyledTableCell>
                                <StyledTableCell align="center">Ends on</StyledTableCell>
                                <StyledTableCell align="center">Total Users</StyledTableCell>
                                {/* <StyledTableCell align="center">Users Reached</StyledTableCell> */}
                                <StyledTableCell align="center">Logs</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {campaignsList.map((row) => (
                                <StyledTableRow key={row.campaignId}>
                                    <StyledTableCell scope="row">
                                        {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{getStatusOfCampaign(Number(row.end_date))}</StyledTableCell>
                                    <StyledTableCell align="center">{row.type}</StyledTableCell>
                                    <StyledTableCell align="center">{convertMillisecondsToDate(Number(row.start_date))}</StyledTableCell>
                                    <StyledTableCell align="center">{convertMillisecondsToDate(Number(row.end_date))}</StyledTableCell>
                                    <StyledTableCell align="center">{row.total_users | 0}</StyledTableCell>
                                    {/* <StyledTableCell align="center">{row.reached_users | 0}</StyledTableCell> */}
                                    <StyledTableCell align="center" className='cursor-pointer' onClick={() => getCampaignsLogHandler(row.type, row.template)}>View</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            }

            {
                showingLogs && <div className='campaign__list__container_2'>
                <TableContainer component={Paper} className=''>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>UID</StyledTableCell>
                                <StyledTableCell align="center">Template</StyledTableCell>
                                <StyledTableCell align="center">Time</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {campaignLogs.map((row) => (
                                <StyledTableRow key={row.campaignId}>
                                    <StyledTableCell align="center">{row.uid}</StyledTableCell>
                                    <StyledTableCell align="center">{row.campaign_template}</StyledTableCell>
                                    <StyledTableCell align="center">{showTimeInAgoFormat(Number(row.time))}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            }
            
        </>
    );
}