import { Box, Button, Chip, Grid, Input, Switch } from '@mui/material'
import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import secureLocalStorage from 'react-secure-storage'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { showTimeInAgoFormat } from '../../Utils/time'
import { capitalizeFirstLetter } from '../../Utils/string'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#4F709C',
        color: theme.palette.common.white,
        fontSize: 16
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

function CommunityUser() {
    const [currentUID, setCurrentUID] = useState('')
    const [apiCalled, setApiCalled] = useState(false)
    const token = secureLocalStorage.getItem('token')
    const dashboardUid = secureLocalStorage.getItem('uid')
    const [blockedList, setBlockedList] = useState([])
    const [communityLimits, setCommunityLimits] = useState({ chat: 0, post: 0, comment: 0 })

    const getUserInfo = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}`, dashboardUid }
        }
        fetch(`https://dev.blockerx.net/api/user/dashboard/community/${currentUID}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setBlockedList(data.data.blockedList)
                    setCommunityLimits(data.data.communityLimits)
                    setApiCalled(true)
                }
            })
            .catch((error) => {})
    }

    return (
        <>
            <div>
                <Box sx={{ flexGrow: 2 }}>
                    <Grid align='center' justify='center' spacing={2} sx={{ marginBottom: '100px' }}>
                        <h1 className='header-top mt-5'>User Information</h1>
                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <Input
                                disablePortal
                                placeholder='User ID'
                                id='combo-box-demo'
                                sx={{ width: '400px' }}
                                onChange={(event, item) => {
                                    setCurrentUID(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <Button className='h-12' sx={{ width: '400px' }} onClick={getUserInfo} color='info' variant='contained'>
                                Get Community Info
                            </Button>
                        </Grid>
                    </Grid>

                    {apiCalled && (
                        <div class='grid grid-cols-2 gap-4'>
                            <Grid item lg={4} md={6} sm={12} className='p' sx={{ marginX: 5 }}>
                                <h1 className='text-2xl font-bold my-4'>Community Status</h1>
                                <TableContainer component={Paper} className=''>
                                    <Table aria-label='customized table'>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Community Feature</StyledTableCell>
                                                <StyledTableCell>User</StyledTableCell>
                                                <StyledTableCell>Limit</StyledTableCell>
                                                <StyledTableCell>Status</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.entries(communityLimits).map(([key, value]) => (
                                                <StyledTableRow key='uidgsdg'>
                                                    <StyledTableCell component='th' scope='row'>
                                                        Community {capitalizeFirstLetter(key)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align='left'>{blockedList.length}</StyledTableCell>
                                                    <StyledTableCell align='left'>{value}</StyledTableCell>
                                                    <StyledTableCell align='left'>
                                                        <Chip label={blockedList.length >= value ? 'Disabled' : 'Enabled'} sx={{ marginLeft: '10px', marginBottom: '4px', cursor: 'pointer' }} color={blockedList.length >= value ? 'error' : 'success'} />
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item lg={4} md={6} sm={12} className='p' sx={{ marginX: 5 }}>
                                <h1 className='text-2xl font-bold my-4'>User Blocklist</h1>
                                <TableContainer component={Paper} className=''>
                                    <Table aria-label='customized table'>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>S. No</StyledTableCell>
                                                <StyledTableCell align='center'>Username</StyledTableCell>
                                                <StyledTableCell align='center'>Date</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {blockedList.map((row, index) => (
                                                <StyledTableRow key={row._id}>
                                                    <StyledTableCell className='cursor-pointer hover:underline' scope='row'>
                                                        {index + 1}
                                                    </StyledTableCell>
                                                    <StyledTableCell align='center'>{row.username}</StyledTableCell>
                                                    <StyledTableCell align='center'> {showTimeInAgoFormat(Number(row.time))}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </div>
                    )}
                </Box>
                <div className='pb-32'></div>
            </div>
        </>
    )
}

export default CommunityUser
