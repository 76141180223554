import secureLocalStorage from 'react-secure-storage'

const isAuthenticated = () => {
    try {
        let userState = false
        const isUserAuthenticated = secureLocalStorage.getItem('isAuth')
        if (isUserAuthenticated === true) {
            userState = true
        }
        return userState
    } catch {
        return false
    }
}

export default isAuthenticated
