import React, { useEffect, useState } from 'react'
import { BarChart } from '@mui/x-charts/BarChart'
import { ZENZE_BASE_URL } from '../../../api/Constants'

const filterOptions = [
    { id: 'screentime', label: 'Average Screentime', chartLabel: 'Average Screentime Per Day (hrs)' },
]

function ZenzeAverageScreentimeAnalysisV2() {
    const [apiCalled, setApiCalled] = useState(false)
    const [labelArray, setLabelArray] = useState([])
    const [count1to4Hours, setCount1to4Hours] = useState([])
    const [count4to8Hours, setCount4to8Hours] = useState([])
    const [countGreaterThan8Hours, setCountGreaterThan8Hours] = useState([])
    const [activeFilter, setActiveFilter] = useState('screentime')
    const [chartLabel, setChartLabel] = useState('Average Screentime Per Day (hrs)')

    const handleTicketStats = (ticketStatsList) => {
        const labels = []
        const count1to4 = []
        const count4to8 = []
        const countGreater8 = []

        ticketStatsList.forEach((ticket) => {
            labels.push(ticket.dateMonth)
            count1to4.push(ticket.count1to4Hours)
            count4to8.push(ticket.count4to8Hours)
            countGreater8.push(ticket.countGreaterThan8Hours)
        })

        setLabelArray(labels)
        setCount1to4Hours(count1to4)
        setCount4to8Hours(count4to8)
        setCountGreaterThan8Hours(countGreater8)
    }

    const getFeedbackRatingStats = (type) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }

        fetch(`${ZENZE_BASE_URL}/api/stats/v2/average-screentime`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setApiCalled(true)
                    handleTicketStats(data.data.aggregationResults)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    useEffect(() => {
        if (!apiCalled) {
            getFeedbackRatingStats(activeFilter)
        }
    }, [apiCalled, activeFilter])

    const handleFilterChange = (filter) => {
        setActiveFilter(filter.id)
        setChartLabel(filter.chartLabel)
        setApiCalled(false)  // Reset apiCalled to false to trigger useEffect
    }

    return (
        <>
            <div>
                <p className='text-center text-4xl font-bold mt-12'>Zenze Screentime - Parts</p>
            </div>
            <div className="filter-buttons text-center mt-4">
                {filterOptions.map((filter) => (
                    <button
                        key={filter.id}
                        className={`filter-button px-4 py-2 m-2 rounded ${activeFilter === filter.id ? 'bg-gray-800 text-white' : 'bg-gray-200'}`}
                        onClick={() => handleFilterChange(filter)}
                    >
                        {filter.label}
                    </button>
                ))}
            </div>

            {labelArray.length > 0 && (
                <div className='pt-8'>
                    <BarChart
                        xAxis={[
                            {
                                id: 'barCategories',
                                data: labelArray,
                                scaleType: 'band'
                            }
                        ]}
                        series={[
                            {
                                data: count1to4Hours,
                                label: '1-4 Hours',
                                color: '#3366cc'
                            },
                            {
                                data: count4to8Hours,
                                label: '4-8 Hours',
                                color: '#dc3912'
                            },
                            {
                                data: countGreaterThan8Hours,
                                label: '>8 Hours',
                                color: '#ff9900'
                            }
                        ]}
                        height={500}
                        sx={{ margin: '10px' }}
                    />
                </div>
            )}
        </>
    )
}

export default ZenzeAverageScreentimeAnalysisV2
