import secureLocalStorage from 'react-secure-storage'
import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Button, Card, CardActions, CardHeader, Grid, IconButton, Input, Snackbar, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MuiAlert from '@mui/material/Alert';
import WhatshotIcon from '@mui/icons-material/Whatshot';

import DoneIcon from '@mui/icons-material/Done';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from "react-ga";

import './Streak.css'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Streak() {

    const [uid, setUid] = useState("");
    const [streakArr, setStreakArr] = useState([]);

    let navigate = useNavigate();
    const token = secureLocalStorage.getItem("token");
    const dashboardUid = secureLocalStorage.getItem("uid");

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const StreakDataHandler = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "uid": uid })
        };
        fetch('https://healify.blockerx.net/api/dashboard/userStreakData', requestOptions)
            .then(response => response.json())
            .then((data) => {
                data.data.reverse();
                setStreakArr(data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const resetAllFields = () => {
    }

    const deleteStreakHandler = (streakId) => {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "uid": uid,"streakId": streakId, "dashboardUid": dashboardUid, type: "deleteStreak" })
        };

        fetch('https://healify.blockerx.net/api/dashboard/modifyStreak', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === "success") {
                    setOpenSuccessAlert(true)
                    StreakDataHandler()
                } else if (data.status === "invalid_token") {
                    secureLocalStorage.clear();
                    navigate("/forbidden");
                } else {
                    setOpenErrorAlert(true);
                    resetAllFields();
                }
            });
    }

    const makeItActiveStreakHandler = (streakId) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "uid": uid,"streakId": streakId, "dashboardUid": dashboardUid, "type": "activeStreak" })
        };

        fetch('https://healify.blockerx.net/api/dashboard/modifyStreak', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === "success") {
                    setOpenSuccessAlert(true)
                    StreakDataHandler()
                } else if (data.status === "invalid_token") {
                    secureLocalStorage.clear();
                    navigate("/forbidden");
                } else {
                    setOpenErrorAlert(true);
                    resetAllFields();
                }
            });
    }

    const makeItCompleteStreakHandler = (streakId) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "uid": uid, "streakId": streakId, "dashboardUid": dashboardUid, "type": "completeStreak" })
        };

        fetch('https://healify.blockerx.net/api/dashboard/modifyStreak', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === "success") {
                    setOpenSuccessAlert(true)
                    StreakDataHandler()
                } else if (data.status === "invalid_token") {
                    secureLocalStorage.clear();
                    navigate("/forbidden");
                } else {
                    setOpenErrorAlert(true);
                    resetAllFields();
                }
            });
    }

    const convertDate = (date) => {
        let d = date;

        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

        return `${da}-${mo}-${ye}`;
    }

    const convertDays = (days) => {
        if (days % 1 === 0) {
            return days;
        }
        return days.toFixed(2);
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    return (
        <>
            <h1 className='text-5xl text-center mt-10 mb-5'> Get Streaks of User </h1>
            <Grid align="center" justify="center" spacing={2}>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <Input
                        disablePortal
                        placeholder="Enter uid"
                        id="combo-box-demo"
                        sx={{ width: "400px" }}
                        onChange={(event, item) => {
                            setUid(event.target.value);
                        }}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <Button
                        className="h-12"
                        sx={{ width: "400px" }}
                        onClick={StreakDataHandler}
                        variant="contained">
                        Get Streak Data
                    </Button>
                </Grid>
            </Grid>

            <Box sx={{ marginTop: '30px', marginBottom: '50px' }} >
                {streakArr.length > 0 ? <h1 className='text-4xl text-center my-5'>User Streaks</h1> : " "}
                {streakArr.map((streak) => {
                    if (streak.status === "complete") {
                        return (
                            <Grid className='outerGrid' align="center" justify="center" sx={{ width: "450px", margin: "0 auto" }} spacing={2} id={streak._id}>
                                <Grid item lg={4} md={6} sm={12} className="pt-6" sx={{ width: "450px" }}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={6}>
                                            <Card sx={{ width: "450px", backgroundColor: "#f1f1f1" }} id={streak._id}>
                                                <CardHeader
                                                    title={"No of Days : " + convertDays(streak.totalDays)}
                                                    align="center"
                                                />
                                                <Typography>Start Date : {convertDate(streak.startTime)} </Typography>
                                                <Typography sx={{ marginBottom: "20px" }}>End Date : {convertDate(streak.endTime)} </Typography>
                                                <CardActions sx={{ backgroundColor: "#f5f6f7" }}>
                                                    <IconButton aria-label="add to favorites" onClick={() => makeItActiveStreakHandler(streak._id)}>
                                                        <WhatshotIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="share" onClick={() => makeItCompleteStreakHandler(streak._id)}>
                                                        <DoneIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="delete" onClick={() => deleteStreakHandler(streak._id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    } else {
                        return (
                            <Grid className='outerGrid' align="center" justify="center" sx={{ width: "450px", margin: "0 auto" }} spacing={2} id={streak._id}>
                                <Grid item lg={4} md={6} sm={12} className="pt-6" sx={{ width: "450px" }}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={6}>
                                            <Card sx={{ width: "450px", backgroundColor: "#379237 ", color: "white" }} id={streak._id}>
                                                <CardHeader
                                                    title={"No of Days : " + convertDays(streak.totalDays)}
                                                    align="center"
                                                />
                                                <Typography sx={{ marginBottom: "20px" }}>Start Date : {convertDate(streak.startTime)} </Typography>
                                                <CardActions sx={{ backgroundColor: "#f5f6f7" }}>
                                                    <IconButton aria-label="add to favorites" onClick={() => makeItActiveStreakHandler(streak._id)}>
                                                        <WhatshotIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="share" onClick={() => makeItCompleteStreakHandler(streak._id)}>
                                                        <DoneIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="delete" onClick={() => deleteStreakHandler(streak._id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    }
                })}
            </Box>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                        Success!!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                        Error!!!
                    </Alert>
                </Snackbar>
            </Stack>
        </>
    );
}
