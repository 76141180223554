import secureLocalStorage from 'react-secure-storage'
import './Coins.css'
import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import {Autocomplete, Avatar, Button, Card, CardContent, CardHeader, Grid, Input, Snackbar, TextField, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';

import MuiAlert from '@mui/material/Alert';

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const datasetType = [
    {
        label: 50
    },
    {
        label: 350
    },
    {
        label: 1500
    }, 
    {
        label: 1550
    },
    {
        label: 18250
    }
];


const Coins = () => {

    const [apiCalled, setApiCalled] = useState(false);
    const [coins, setCoins] = useState(50);
    const [inputUid, setInputUid] = useState("");
    
    let navigate = useNavigate();
    const token = secureLocalStorage.getItem("token");
    const dashboardUid = secureLocalStorage.getItem("uid");

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const resetAllFields = () => {
        setApiCalled(false);
        setInputUid("");
        setCoins(50)
    }

    const giveCoinsHandler = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "coins": coins, "uid": inputUid, "dashboardUid": dashboardUid })
        };

        fetch('https://healify.blockerx.net/api/dashboard/giveCoins', requestOptions)
            .then(response => response.json())
            .then((data) => {
                if (data.status === "success") {
                    setApiCalled(true);
                    setOpenSuccessAlert(true)
                    resetAllFields()
                } else if (data.status === "invalid_token") {
                    secureLocalStorage.clear();
                    navigate("/tokenExpired");
                } else {
                    setOpenErrorAlert(true);
                    resetAllFields();
                }
            });
    }

    return (
        <>
            <h1 className='text-5xl text-center mt-10'>Give Coins To User</h1>
            <Grid align="center" justify="center" spacing={2}>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <Input
                        disablePortal
                        placeholder="Enter uid"
                        value={inputUid}
                        sx={{ width: "400px" }}
                        onChange={(event, item) => {
                            setInputUid(event.target.value);
                        }}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={datasetType}
                        value={coins}
                        sx={{ width: "400px" }}
                        onChange={(event, item) => {
                            setCoins(item.label)
                        }}
                        renderInput={(params) => <TextField {...params} label="Choose number of coins" />}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <Button
                        className="h-12"
                        sx={{ width: "400px" }}
                        onClick={giveCoinsHandler}
                        variant="contained">
                        Give Coins
                    </Button>
                </Grid>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                        <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                            Success!!!
                        </Alert>
                    </Snackbar>
                </Stack>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                        <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                            Error!!!
                        </Alert>
                    </Snackbar>
                </Stack>
            </Grid>
        </>
    )
}

export default Coins