/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { BarChart } from '@mui/x-charts/BarChart'
import { getDayMonthFromMs } from '../../../Utils/time'
import { BASE_URL, ZENZE_BASE_URL } from '../../../api/Constants'

const filterOptions = [
    { id: 'detox', label: 'Detox Sessions', chartLabel: 'Detox Sessions Started' },
    { id: 'schedule', label: 'Schedule Sessions', chartLabel: 'Schedule Sessions Started' },
    { id: 'focus', label: 'Focus Sessions', chartLabel: 'Focus Sessions Started' },
]

function ZenzeActiveSessionAnalysis() {
    const [apiCalled, setApiCalled] = useState(false)
    const [labelArray, setLabelArray] = useState([])
    const [ongoingArray, setOngoingArray] = useState([])
    const [endedArray, setEndedArray] = useState([])
    const [activeFilter, setActiveFilter] = useState('detox')
    const [chartLabel, setChartLabel] = useState('Detox Sessions Started')
    const [showAccumulated, setShowAccumulated] = useState(false)

    const handleTicketStats = (ticketStatsList) => {
        const labels = []
        const ongoing_counts = []
        const ended_counts = []
        ticketStatsList.forEach((ticket) => {
            labels.push(getDayMonthFromMs(ticket.time))
            ongoing_counts.push(ticket.ongoingCount)
            ended_counts.push(ticket.endCount)
        })

        if (showAccumulated) {
            for (let i = 1; i < ongoing_counts.length; i++) {
                ongoing_counts[i] += ongoing_counts[i - 1]
                ended_counts[i] += ended_counts[i - 1]
            }
        }

        setLabelArray(labels)
        setOngoingArray(ongoing_counts)
        setEndedArray(ended_counts)
    }

    const getFeedbackRatingStats = (type) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }

        fetch(`${ZENZE_BASE_URL}/api/stats/v1/active-sessions?type=${type}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setApiCalled(true)
                    handleTicketStats(data.data.aggregationResults)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    useEffect(() => {
        if (!apiCalled) {
            getFeedbackRatingStats(activeFilter)
        }
    }, [apiCalled, activeFilter, showAccumulated])

    const handleFilterChange = (filter) => {
        setActiveFilter(filter.id)
        setChartLabel(filter.chartLabel)
        setApiCalled(false)  // Reset apiCalled to false to trigger useEffect
    }

    const toggleAccumulated = () => {
        setShowAccumulated(!showAccumulated)
        setApiCalled(false) // Re-fetch data to recalculate stats
    }

    return (
        <>
            <div>
                <p className='text-center text-4xl font-bold mt-12'>Active Sessions</p>
            </div>
            <div className="filter-buttons text-center mt-4">
                {filterOptions.map((filter) => (
                    <button
                        key={filter.id}
                        className={`filter-button px-4 py-2 m-2 rounded ${activeFilter === filter.id ? 'bg-gray-800 text-white' : 'bg-gray-200'}`}
                        onClick={() => handleFilterChange(filter)}
                    >
                        {filter.label}
                    </button>
                ))}
            </div>
            <div className="flex justify-center mt-6">
                <label className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        checked={showAccumulated}
                        onChange={toggleAccumulated}
                        className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 rounded"
                    />
                    <span className="text-lg font-medium text-gray-700">Show accumulated count</span>
                </label>
            </div>
            {labelArray.length > 0 && (
                <div className='pt-8'>
                    <BarChart
                        series={[
                            { data: endedArray, stack: 'A', label: 'Stopped Sessions', color: '#CD5C5C' },
                            { data: ongoingArray, stack: 'A', label: 'Active Sessions', color: '#17B169' },
                        ]}
                        xAxis={[
                            {
                                id: 'barCategories',
                                data: labelArray,
                                scaleType: 'band'
                            }
                        ]}
                        height={500}
                        sx={{ margin: '10px' }}
                    />
                </div>
            )}
        </>
    )
}

export default ZenzeActiveSessionAnalysis
