import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { ZENZE_BASE_URL } from '../../api/Constants';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { showTimeInAgoFormat } from '../../Utils/time';

const ZenzeInstagramChallenge = () => {
  const [data, setData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedUID, setSelectedUID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const updateList = () => {
    axios.get(`${ZENZE_BASE_URL}/api/v1/social/user-screenshots`)
      .then(response => {
        console.log('response.data.data.list: ', response.data.data.list)
        setData(response.data.data.list);
        setLoading(false);
      })
      .catch(error => {
        setError('Failed to fetch data.');
        setLoading(false);
      });
  }

  useEffect(() => {
    updateList()
  }, []);

  const openModal = (imageUrl, uid) => {
    setSelectedImage(imageUrl);
    setSelectedUID(uid)
  };

  const closeModal = () => {
    setSelectedImage(null);
    setSelectedUID(null)
  };

  const verifyImage = () => {
    axios.post(`${ZENZE_BASE_URL}/api/v1/social/verify-and-reward`, {
      uid: selectedUID,
      app: "instagram",
    })
      .then(response => {
        closeModal();
        updateList()
      })
      .catch(error => {
        console.error('Error verifying image and granting reward:', error);
      });
  };

  if (loading) {
    return <div className="text-center py-8">Loading...</div>;
  }

  if (error) {
    return <div className="text-center py-8 text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8">Zenze Instagram Challenge</h1>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User ID</TableCell>
              <TableCell align="center">Image</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Actions</TableCell>
              <TableCell align="center">Uploaded</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow key={item._id}>
                <TableCell>{item.Uid}</TableCell>
                <TableCell align="center">
                  <img
                    src={item.ImageURL}
                    alt={`Uploaded by ${item.Uid}`}
                    style={{ maxWidth: '100px', maxHeight: '100px', borderRadius: '8px' }}
                  />
                </TableCell>
                <TableCell align="center">
                  {item.Verified ? (
                    <span className="text-green-500 font-bold">Verified</span>
                  ) : (
                    <span className="text-red-500 font-bold">Not Verified</span>
                  )}
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => openModal(item.ImageURL, item.Uid)}>
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
                <TableCell>{showTimeInAgoFormat (item.UploadedAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedImage && (
        <Modal
          isOpen={!!selectedImage}
          onRequestClose={closeModal}
          contentLabel="Zoomed Image"
          shouldCloseOnOverlayClick={true}
          className="flex items-center justify-center bg-black bg-opacity-75 p-4"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50"
        >
          <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-black text-2xl font-bold"
            >
              &times;
            </button>
            <div className="overflow-y-auto max-h-[70vh]">
              <img
                src={selectedImage}
                alt="Zoomed"
                className="w-full h-auto rounded-lg mb-4"
              />
            </div>
            <div className="sticky bottom-0 bg-white pt-4">
              <Button
                onClick={verifyImage}
                color="primary"
                variant="contained"
                fullWidth
              >
                Verify
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ZenzeInstagramChallenge;
