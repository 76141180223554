import React, { useEffect, useState } from 'react'
import './UserTicketHistory.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { showTimeInAgoFormat } from '../../../Utils/time'

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const showTicketStatus = (text) => {
    const words = text.split('_')
    let result = ''
    words.forEach((word) => {
        result += capitalizeFirstLetter(word) + ' '
    })
    return result
}

function UserTicketHistory() {
    const location = useLocation()
    const navigate = useNavigate()
    const [userEmail, setUserEmail] = useState('')
    const [ticketsList, setTicketsList] = useState([])
    const [apiCalled, setApiCalled] = useState(false)

    const getTicketHistoryOfUser = (email) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: email
            })
        }

        fetch(`https://dev.blockerx.net/api/dashboard/tickets/history`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    const allTickets = data.data.list
                    setTicketsList(allTickets)
                    setApiCalled(true)
                } else {
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    useEffect(() => {
        if (!apiCalled) {
            const path = location.pathname
            const user_email = path.substring(24)
            getTicketHistoryOfUser(user_email)
            setUserEmail(user_email)
            window.scrollTo(0, 0)
        }
    }, [apiCalled, location.pathname])

    const openTicketHandler = (ticketId) => {
        navigate(`/customer-tickets/${ticketId}`)
    }
    return (
        <>
            <div className='text-2xl font-bold text-center mt-10'>Tickets History - {userEmail}</div>
            <div className='tickets__history__container'>
                {ticketsList.length > 0 &&
                    ticketsList.map((eachTicket) => {
                        return (
                            <div className='ticket__container cursor-pointer' onClick={() => openTicketHandler(eachTicket._id)}>
                                <div className='each__ticket__container'>
                                    <div className='font-bold'>
                                        {' '}
                                        {capitalizeFirstLetter(eachTicket.tag) + ' issue in '} {capitalizeFirstLetter(eachTicket.appName) + ' '}
                                        {capitalizeFirstLetter(eachTicket.platform)}
                                    </div>
                                </div>
                                <div className='each__ticket__container'>
                                    <div> User Responsed : {showTimeInAgoFormat(eachTicket.updatedAt)}</div>
                                    <div> Customer Team : {showTimeInAgoFormat(eachTicket.respondedAt)}</div>
                                </div>
                                <div className='each__ticket__container'>
                                    <div>Description: {eachTicket.description}</div>
                                </div>
                                <div className='each__ticket__container'>
                                    <div className='font-bold text-red-700'> {showTicketStatus(eachTicket.status)}</div>
                                    <div>Ticket Created : {showTimeInAgoFormat(eachTicket.time)}</div>
                                </div>
                            </div>
                        )
                    })}
                {ticketsList.length === 0 && <div className='ticket__container text-center'> Not found </div>}
            </div>
        </>
    )
}

export default UserTicketHistory
