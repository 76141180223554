import secureLocalStorage from 'react-secure-storage'
import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import AdbIcon from '@mui/icons-material/Adb'
import GridViewIcon from '@mui/icons-material/GridView'

import { useNavigate } from 'react-router-dom'
import { grey } from '@mui/material/colors'

const Header = () => {
    const fullname = secureLocalStorage.getItem('fullname') || 'Anonymous User'
    const navigate = useNavigate()

    const homeHandler = () => {
        setAnchorElNav(null)
        navigate('/')
    }

    const profileHandler = () => {
        setAnchorElNav(null)
        navigate('/profile')
    }

    const dashboardStatsHandler = () => {
        navigate('stats')
        setAnchorElNav(null)
    }

    const activityHandler = () => {
        navigate('activity')
        setAnchorElNav(null)
    }

    const peersHandler = () => {
        navigate('peers')
        setAnchorElNav(null)
    }

    const customerTicketsHandler = () => {
        navigate('/customer-tickets')
    }

    const developerHandler = () => {
        navigate('/developer-tickets')
    }

    const [anchorElNav, setAnchorElNav] = React.useState(null)
    const [anchorElUser, setAnchorElUser] = React.useState(null)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    }

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }
    return (
        <AppBar position='static'>
            <Container maxWidth='100%' sx={{ backgroundColor: '#3C4048' }}>
                <Toolbar disableGutters>
                    <GridViewIcon
                        sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
                    />
                    <Typography
                        variant='h6'
                        noWrap
                        component='a'
                        href='/'
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'roboto',
                            fontWeight: 700,
                            fontSize: 25,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none'
                        }}>
                        DASHBOARD
                    </Typography>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'flex', md: 'none' }
                        }}>
                        <IconButton
                            size='large'
                            aria-label='account of current user'
                            aria-controls='menu-appbar'
                            aria-haspopup='true'
                            onClick={handleOpenNavMenu}
                            color='inherit'>
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id='menu-appbar'
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' }
                            }}>
                            <MenuItem key='Home' onClick={homeHandler}>
                                <Typography textAlign='center'>Home</Typography>
                            </MenuItem>
                            <MenuItem key='Profile' onClick={profileHandler}>
                                <Typography textAlign='center'>
                                    Profile
                                </Typography>
                            </MenuItem>
                            <MenuItem key='Activity' onClick={activityHandler}>
                                <Typography textAlign='center'>
                                    Activity
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </Box>

                    <AdbIcon
                        sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}
                    />
                    <Typography
                        variant='h5'
                        noWrap
                        component='a'
                        href=''
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none'
                        }}>
                        DASHBOARD
                    </Typography>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'flex' }
                        }}>
                        <Button
                            key='activity1'
                            onClick={customerTicketsHandler}
                            sx={{
                                color: 'white',
                                display: 'block',
                                fontSize: '16px'
                            }}>
                            Tickets
                        </Button>
                        <Button
                            key='activity2'
                            onClick={developerHandler}
                            sx={{
                                color: 'white',
                                display: 'block',
                                fontSize: '16px'
                            }}>
                            Developers
                        </Button>
                        <Button
                            key='activity4'
                            onClick={activityHandler}
                            sx={{
                                color: 'white',
                                display: 'block',
                                fontSize: '16px'
                            }}>
                            Activity
                        </Button>
                        <Button
                            key='Stats'
                            onClick={dashboardStatsHandler}
                            sx={{
                                color: 'white',
                                display: 'block',
                                fontSize: '16px'
                            }}>
                            Stats
                        </Button>
                        <Button
                            key='Peers'
                            onClick={peersHandler}
                            sx={{
                                color: 'white',
                                display: 'block',
                                fontSize: '16px'
                            }}>
                            Peers
                        </Button>
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title='Open settings'>
                            <IconButton
                                onClick={handleOpenUserMenu}
                                sx={{ p: 0 }}>
                                <Avatar
                                    alt={fullname.substr(0, 1)}
                                    src={{}}
                                    sx={{ bgcolor: grey[600] }}
                                />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id='menu-appbar'
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}>
                            <MenuItem key='Profile' onClick={profileHandler}>
                                <Typography textAlign='center'>
                                    Profile
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Header
