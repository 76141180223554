import { CircularProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect } from 'react'
import { useState } from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineDot from '@mui/lab/TimelineDot'
import LaptopMacIcon from '@mui/icons-material/LaptopMac'

const DashboardActivity = () => {
    const [pageLoaded, setPageLoaded] = useState(false)
    const [activity, setActivity] = useState([])
    const [apiCalled, setApiCalled] = useState(false)

    const timeAgo = (time) => {
        switch (typeof time) {
            case 'number':
                break
            case 'string':
                time = +new Date(time)
                break
            case 'object':
                if (time.constructor === Date) time = time.getTime()
                break
            default:
                time = +new Date()
        }
        var time_formats = [
            [60, 'seconds', 1], // 60
            [120, '1 minute ago', '1 minute from now'], // 60*2
            [3600, 'minutes', 60], // 60*60, 60
            [7200, '1 hour ago', '1 hour from now'], // 60*60*2
            [86400, 'hours', 3600], // 60*60*24, 60*60
            [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
            [604800, 'days', 86400], // 60*60*24*7, 60*60*24
            [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
            [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
            [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
            [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
            [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
            [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
            [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
            [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
        ]
        var seconds = (+new Date() - time) / 1000,
            token = 'ago',
            list_choice = 1

        if (seconds === 0) {
            return 'Just now'
        }
        if (seconds < 0) {
            seconds = Math.abs(seconds)
            token = 'from now'
            list_choice = 2
        }
        var i = 0,
            format
        while ((format = time_formats[i++]))
            if (seconds < format[0]) {
                if (typeof format[2] == 'string') return format[list_choice]
                else return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token
            }
        return time
    }

    useEffect(() => {
        setTimeout(() => {
            setPageLoaded(true)
        }, 300)

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch('https://dev.blockerx.net/api/dashboard/activity', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (data.status === 200) {
                    setActivity(data.data.activity)
                    setApiCalled(true)
                } else {
                }
            })
    }, [])

    const openEachTicketContainer = (ticketId) => {
        window.open(`/customer-tickets/${ticketId}`, '_blank')
    }

    return apiCalled === true ? (
        <Timeline position=''>
            {activity.map((eachAcitivity) => {
                return (
                    <TimelineItem>
                        <TimelineOppositeContent sx={{ m: 'auto 0' }} align='right' variant='body2' color='text.secondary'>
                            {timeAgo(eachAcitivity.time)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color='primary'>
                                <LaptopMacIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant='h6' component='span'>
                                {eachAcitivity.fullname}
                            </Typography>
                            <Typography>
                                <span>{eachAcitivity.usedFor + ' '}</span>
                                <span className='cursor-pointer hover:underline' onClick={() => openEachTicketContainer(eachAcitivity.uid)}>{eachAcitivity.uid}</span>
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>
                )
            })}
        </Timeline>
    ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '300px' }}>
            <CircularProgress size='7rem' color='grey' />
        </Box>
    )
}

export default DashboardActivity
