import React, { useEffect, useState } from 'react'
import { BarChart } from '@mui/x-charts/BarChart'
import { getDayMonthFromMs } from '../../../Utils/time'

const filterOptions = [
  { id: 'streak', label: 'Streak started each day', chartLabel: 'Streaks started each day' },
]

function ZenzeStreakAnalysis() {
  const [apiCalled, setApiCalled] = useState(false)
  const [labelArray, setLabelArray] = useState([])
  const [countArray, setCountArray] = useState([])
  const [activeFilter, setActiveFilter] = useState('streak')
  const [chartLabel, setChartLabel] = useState('Streaks started each day')

  const handleTicketStats = (ticketStatsList) => {
    const labels = []
    const counts = []
    ticketStatsList.forEach((ticket) => {
      labels.push(getDayMonthFromMs(ticket.time))
      counts.push(ticket.count)
    })
    setLabelArray(labels)
    setCountArray(counts)
  }

  const getFeedbackRatingStats = (type) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }

    fetch(`https://zenze.blockerx.net/api/sessions/v2/stats?type=${type}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log('Data : ', data)
        if (data.status === 200) {
          setApiCalled(true)
          handleTicketStats(data.data.aggregationResults)
        }
      })
      .catch((error) => {
        console.log('Error : ', error)
      })
  }

  useEffect(() => {
    if (!apiCalled) {
      getFeedbackRatingStats(activeFilter)
    }
  }, [apiCalled, activeFilter])

  const handleFilterChange = (filter) => {
    setActiveFilter(filter.id)
    setChartLabel(filter.chartLabel)
    setApiCalled(false)
  }

  return (
    <>
      <div>
        <p className='text-center text-4xl font-bold mt-12'>Zenze Streak Analysis</p>
      </div>
      <div className="filter-buttons text-center mt-4">
        {filterOptions.map((filter) => (
          <button
            key={filter.id}
            className={`filter-button px-4 py-2 m-2 rounded ${activeFilter === filter.id ? 'bg-gray-800 text-white' : 'bg-gray-200'}`}
            onClick={() => handleFilterChange(filter)}
          >
            {filter.label}
          </button>
        ))}
      </div>
      {labelArray.length > 0 && (
        <div className='ticketfeedback__container'>
          <BarChart
            xAxis={[
              {
                id: 'barCategories',
                data: labelArray,
                scaleType: 'band'
              }
            ]}
            series={[
              {
                data: countArray,
                label: chartLabel,
                color: '#33414d'
              }
            ]}
            height={500}
            sx={{ margin: '10px'}}
          />
        </div>
      )}
    </>
  )
}

export default ZenzeStreakAnalysis
