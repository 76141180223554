import React, { useState } from 'react';
import { Grid, Typography, Paper, styled } from '@mui/material';
import { ArrowBack, House, Person, Timer, Watch } from '@mui/icons-material';
import ZenzeSessionAnalysis from './ZenzeSessionAnalysis';
import ZenzeStreakAnalysis from './ZenzeStreakAnalysis';
import ZenzeActiveSessionAnalysis from './ZenzeActiveSessionAnalysis.jsx';
import ZenzeReferredUsers from './ZenzeReferredUsers.jsx';
import ZenzeRoomsLastMonth from './ZenzeRoomsLastMonth.jsx';
import ZenzeRecentRooms from './ZenzeRecentRooms.jsx';
import ZenzeStreakReset30Days from './ZenzeStreakReset30Days.jsx';
import ZenzeAverageScreentimeAnalysisV2 from './ZenzeAverageScreentimeAnalysisV2.jsx';
import ZenzeUsers30Days from './ZenzeUsers30Days.jsx';

const items = [
    { name: 'Session Started Each Day', component: <ZenzeSessionAnalysis />, icon: <Timer /> },
    { name: 'Active Sessions Each Day', component: <ZenzeActiveSessionAnalysis />, icon: <Timer /> },
    // { name: 'Invited Users', component: <ZenzeInviteAnalysis />, icon: <Person /> },
    // { name: 'Coin Rewards', component: <ZenzeRewardAnalysis />, icon: <CardGiftcard /> },
    { name: 'Streaks Started Each Day', component: <ZenzeStreakAnalysis />, icon: <Watch /> },
    // { name: 'Average Screentime', component: <ZenzeAverageScreentimeAnalysis />, icon: <Watch /> },
    { name: 'Average Screentime - Parts', component: <ZenzeAverageScreentimeAnalysisV2 />, icon: <Watch /> },
    // { name: 'Emergency Access', component: <ZenzeEmergencyAccessAnalysis />, icon: <Policy /> },
    { name: 'Recent Rooms', component: <ZenzeRecentRooms />, icon: <House /> },
    // { name: 'FCM Logs', component: <ZenzeFCMLogs />, icon: <Notifications /> },
    { name: 'Referred Users - 30 days', component: <ZenzeReferredUsers />, icon: <Person /> },
    { name: 'Rooms - 30 days', component: <ZenzeRoomsLastMonth />, icon: <Person /> },
    { name: 'Streak Reset - 30 days', component: <ZenzeStreakReset30Days />, icon: <Person /> },
    { name: 'Zenze Users - 30 days', component: <ZenzeUsers30Days />, icon: <Person /> },
    // { name: 'Notifications - 30 days', component: <ZenzeFCMLastMonth />, icon: <Person /> },
].sort((a, b) => a.name.localeCompare(b.name));

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: '16px',
    textAlign: 'center',
    transition: 'transform 0.3s, box-shadow 0.3s',
    '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: theme.shadows[1],
        backgroundColor: '#FDFEFE'
    },
}));

function ZenzeAnalysis() {
    const [selectedComponent, setSelectedComponent] = useState(null);

    const handleComponentSelect = (component) => {
        setSelectedComponent(component);
    };

    return (
        <div>
            {selectedComponent ? (
                <>
                    <div onClick={() => {
                        setSelectedComponent(null)
                    }} className='text-left px-16 pt-8 text-2xl capitalize cursor-pointer'> <ArrowBack /> Home</div>
                    <div>{selectedComponent}</div>
                </>
            ) : (
                <>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{ pt: 4, textAlign: 'center', fontWeight: 'bolder' }}
                    >
                        Zenze Analysis
                    </Typography>
                    <Grid container spacing={3} sx={{ p: 4 }}>
                        {items.map((item, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                                <StyledPaper
                                    sx={{ cursor: 'pointer' }}
                                    elevation={3}
                                    onClick={() => handleComponentSelect(item.component)}
                                >
                                    <Typography variant="h6">
                                        {item.name}
                                        <span className='pl-4'>
                                            {item.icon}
                                        </span>
                                    </Typography>
                                </StyledPaper>
                            </Grid>
                        ))}
                    </Grid>
                </>

            )}
        </div>
    );
}

export default ZenzeAnalysis;
