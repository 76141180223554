import * as React from 'react'
import secureLocalStorage from 'react-secure-storage'
import { useEffect } from 'react'
import { Button, Grid, Input, Snackbar, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import MuiAlert from '@mui/material/Alert'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import ReactGA from 'react-ga'
import { useNavigate } from 'react-router-dom'
import { convertMillisecondsToDate, showTimeInAgoFormat } from '../../Utils/time'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const MailLogs = () => {
    const [apiCalled, setApiCalled] = useState(false)
    const [blockList, setBlockList] = useState([])
    const [inputUid, setInputUid] = useState('')

    let navigate = useNavigate()
    const token = secureLocalStorage.getItem('token')
    const dashboardUid = secureLocalStorage.getItem('uid')

    const [blockedDomains, setBlockedDomains] = useState([])

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const resetAllFields = () => {
        setApiCalled(false)
        setBlockList([])
        setInputUid('')
    }

    const getBlockedListOfUser = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch(`https://events.blockerx.net/api/mail/logs?email=${inputUid}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (data.status === 200) {
                    setApiCalled(true)
                    const array = data.data.logs
                    const sortedArray = array.sort((a, b) => {
                        return b.time - a.time
                    })
                    setBlockedDomains(sortedArray)
                } else if (data.status === 401) {
                    secureLocalStorage.clear()
                    navigate('/tokenExpired')
                } else if (data.status === 401) {
                    secureLocalStorage.clear()
                    navigate('/tokenExpired')
                } else if (data.status === 403) {
                    secureLocalStorage.clear()
                    navigate('/forbidden')
                } else {
                    setOpenErrorAlert(true)
                    resetAllFields()
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])

    return (
        <Box sx={{ flexGrow: 2 }}>
            <Grid align='center' justify='center' spacing={2}>
                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <h1 className='header-top mt-5'> Mail Logs</h1>
                </Grid>

                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <Input
                        disablePortal
                        placeholder='Enter email'
                        id='combo-box-demo'
                        value={inputUid}
                        sx={{ width: '35%' }}
                        onChange={(event, item) => {
                            setInputUid(event.target.value)
                        }}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <Button className='h-12' variant='contained' sx={{ width: '35%' }} color='success' onClick={getBlockedListOfUser}>
                        Get Logs
                    </Button>
                </Grid>

                {apiCalled === true ? (
                    <>
                        <Table sx={{ width: '90%', marginTop: 10, marginBottom: 30 }} aria-label='customized table'>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>S.No</StyledTableCell>
                                    <StyledTableCell align='left'>Template</StyledTableCell>
                                    <StyledTableCell align='left'>Subject</StyledTableCell>
                                    <StyledTableCell align='left'>Added on</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            {blockedDomains.map((eachDomain, index) => {
                                return (
                                    <TableBody>
                                        <StyledTableRow key={eachDomain._id} sx={{ border: '1px solid gray' }}>
                                            <StyledTableCell align='left'>{index + 1}</StyledTableCell>
                                            <StyledTableCell align='left'>{eachDomain.template}</StyledTableCell>
                                            <StyledTableCell align='left'>{eachDomain.subject}</StyledTableCell>
                                            <StyledTableCell align='left'>
                                                {showTimeInAgoFormat(Number(eachDomain.time))} on {convertMillisecondsToDate(Number(eachDomain.time))}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                )
                            })}
                        </Table>
                    </>
                ) : (
                    ''
                )}
            </Grid>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                        Success!!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                        Error!!!
                    </Alert>
                </Snackbar>
            </Stack>
        </Box>
    )
}

export default MailLogs
