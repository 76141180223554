import secureLocalStorage from 'react-secure-storage'
import React, { useEffect, useState } from 'react'
import './ViewTickets.css'
import { useNavigate } from 'react-router-dom'
import { Autocomplete, Box, Chip, CircularProgress, Grid, TextField } from '@mui/material'
import { formatMillisecondsDate, showTimeInAgoFormat } from '../../../Utils/time'
import appleIcon from '../../../assets/images/apple.png'
import androidIcon from '../../../assets/images/android-seeklogo.com.svg'
import windowsIcon from '../../../assets/images/windows.png'
import chromeIcon from '../../../assets/images/chrome.svg'

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const showTicketStatus = (text) => {
    const words = text.split('_')
    let result = ''
    words.forEach((word) => {
        result += capitalizeFirstLetter(word) + ' '
    })
    return result
}

function ViewTickets() {
    const [ticketsList, setTicketsList] = useState([])
    const [filterTicketsList, setFilterTicketsList] = useState([])
    const [currentFilter, setCurrentFilter] = useState('All Tickets')
    const [apiCalled, setApiCalled] = useState(false)
    const dashboardUid = secureLocalStorage.getItem('uid')
    const navigate = useNavigate()

    const openEachTicketContainer = (ticketId) => {
        window.open(`/customer-tickets/${ticketId}`, '_blank')
    }

    const showAllFilterTickets = (STATUS) => {
        if (STATUS === 'all') {
            setFilterTicketsList(ticketsList)
            return
        }

        if (STATUS === 'oldest_first') {
            setFilterTicketsList(ticketsList.reverse())
            return
        }

        if (STATUS === 'agent_replied') {
            const filteredArray = ticketsList.filter((obj) => obj.time !== obj.respondedAt)
            const sortedArray = filteredArray.sort((a, b) => b.respondedAt - a.respondedAt)
            setFilterTicketsList(sortedArray)
            return
        }

        if (STATUS === 'zenze_app') {
            let newTickets = []
            ticketsList.forEach((ticket) => {
                if (ticket.appName === 'Zenze') {
                    newTickets.push(ticket)
                }
            })
            setFilterTicketsList(newTickets)
            return 
        }

        let newTickets = []
        ticketsList.forEach((ticket) => {
            if (ticket.status === STATUS) {
                newTickets.push(ticket)
            }
        })
        setFilterTicketsList(newTickets)
    }

    const ticketStatusList = ['pending', 'tech_review', 'tech_complete', 'awaiting_user_reply', 'closed', 'silent_closed', 'user_follow_up', 'zenze_app']

    const showTicketsByFilter = (list_of_tickets, ticket_status) => {
        try {
            setCurrentFilter(getLabelById(ticket_status))
            document.title = getLabelById(ticket_status)
            if (ticket_status === 'all') {
                const sortedArray = list_of_tickets.sort((a, b) => b.updatedAt - a.updatedAt)
                setFilterTicketsList(sortedArray)
            } else if (ticket_status === 'agent_replied') {
                const filteredArray = list_of_tickets.filter((obj) => obj.time !== obj.respondedAt)
                const sortedArray = filteredArray.sort((a, b) => b.respondedAt - a.respondedAt)
                setFilterTicketsList(sortedArray)
                return
            } else if (ticket_status === 'oldest_first') {
                const sortedArray = list_of_tickets.sort((a, b) => a.time - b.time)
                setFilterTicketsList(sortedArray)
                return
            } else if (ticketStatusList.includes(ticket_status)) {
                let newTickets = []
                list_of_tickets.forEach((ticket) => {
                    if (ticket.status === ticket_status) {
                        newTickets.push(ticket)
                    }
                })
                const sortedArray = newTickets.sort((a, b) => b.updatedAt - a.updatedAt)
                setFilterTicketsList(sortedArray)
            }
        } catch {
            setCurrentFilter(getLabelById('all'))
            document.title = getLabelById('all')
            setFilterTicketsList(list_of_tickets)
        }
    }

    const showTicketsFilterWise = (ticket_list) => {
        try {
            const local_filter = secureLocalStorage.getItem('ticket_filter_id')
            console.log('Local Filter : ', local_filter)
            if (local_filter) {
                showTicketsByFilter(ticket_list, local_filter)
            } else {
                showTicketsByFilter(ticket_list, 'all')
            }
        } catch (err) {
            console.log('Error ::', err)
            showTicketsByFilter(ticket_list, 'all')
        }
    }

    const getPendingTickets = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                dashboardUid: dashboardUid
            })
        }

        fetch(`https://dev.blockerx.net/api/tickets/pending`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    const ticket_list = data.data.list.reverse()
                    setTicketsList(ticket_list)
                    showTicketsFilterWise(ticket_list)
                    setApiCalled(true)
                } else {
                }
            })
            .catch((error) => {})
    }

    const showTicketChips = (createdAt, userRespondedAt, supportRespondedAt, status) => {
        if (status === 'tech_review') {
            return <Chip label='Under Tech Review' sx={{ marginLeft: '10px', marginBottom: '4px' }} color='info' />
        }

        if (status === 'user_follow_up') {
            return (
                <Chip
                    label='User Follow Up'
                    sx={{
                        marginLeft: '10px',
                        marginBottom: '4px',
                        backgroundColor: '#333131',
                        color: '#fff'
                    }}
                />
            )
        }

        if (status === 'awaiting_user_reply') {
            return <Chip label='Awaiting User Reply' sx={{ marginLeft: '10px', marginBottom: '4px' }} color='warning' />
        }

        if (status === 'tech_complete') {
            return <Chip label='Tech Complete' sx={{ marginLeft: '10px', marginBottom: '4px' }} color='info' />
        }

        if (supportRespondedAt > userRespondedAt && status !== 'tech_review') {
            return <Chip label='Awaiting User Reply' sx={{ marginLeft: '10px', marginBottom: '4px' }} color='warning' />
        }

        if (status === 'tech_review') {
            return <Chip label='Under Tech Review' sx={{ marginLeft: '10px', marginBottom: '4px' }} color='info' />
        }

        if (createdAt === supportRespondedAt && new Date().getTime() - createdAt > 86400000 && new Date().getTime() - createdAt < 2 * 86400000) {
            return <Chip label='First Response Due' sx={{ marginLeft: '10px', marginBottom: '4px' }} color='secondary' />
        }

        if (createdAt === supportRespondedAt && new Date().getTime() - createdAt > 2 * 86400000) {
            return <Chip label='Overdue' sx={{ marginLeft: '10px', marginBottom: '4px' }} color='error' />
        }

        if (createdAt === supportRespondedAt) {
            return (
                <>
                    <Chip
                        label='New'
                        sx={{
                            marginLeft: '10px',
                            marginBottom: '4px',
                            marginRight: '10px'
                        }}
                        color='primary'
                    />
                </>
            )
        }

        if (supportRespondedAt < userRespondedAt) {
            return (
                <>
                    <Chip label='User Responded' sx={{ marginLeft: '10px', marginBottom: '4px' }} color='success' />
                    {new Date().getTime() - userRespondedAt > 2 * 86400000 && <Chip label='Overdue' sx={{ marginLeft: '10px', marginBottom: '4px' }} color='error' />}
                </>
            )
        }
    }

    const redirectToClosedTicketsPage = () => {
        navigate('/closed-customer-tickets')
    }

    const textWithEntities = (text) => {
        const decodedText = new DOMParser().parseFromString(text, 'text/html').body.textContent
        return <div dangerouslySetInnerHTML={{ __html: decodedText }} />
    }

    const showTruncatedDescription = (fullDescription) => {
        let words = fullDescription.split(/\s+/)
        let first60Words = words.slice(0, 60).join(' ')

        const decodedFromEntities = textWithEntities(first60Words)
        return decodedFromEntities
    }

    const showTicketTitle = (ticketId, email, time, updatedAt, respondedAt, status, mailTicket, tag, appName) => {
        if (mailTicket) {
            return (
                <h2 className='font-bold mb-1'>
                    <span className='text-red-400'> {'Ticket - ' + ticketId}</span> {email} {showTicketChips(time, updatedAt, respondedAt, status)}{' '}
                </h2>
            )
        } else if (tag === 'GooglePurchase') {
            return (
                <h2 className='font-bold mb-1'>
                    <span className='text-blue-500'> {'Ticket - ' + ticketId}</span> {email} {showTicketChips(time, updatedAt, respondedAt, status)}{' '}
                </h2>
            )
        } else if (appName === 'Zenze') {
            return (
                <h2 className='font-bold mb-1'>
                    <span className='text-[#01A661]'> {'Ticket - ' + ticketId}</span> {email} {showTicketChips(time, updatedAt, respondedAt, status)}{' '}
                </h2>
            )
        } else {
            return (
                <h2 className='font-bold mb-1'>
                    {' '}
                    {'Ticket - ' + ticketId} {email} {showTicketChips(time, updatedAt, respondedAt, status)}{' '}
                </h2>
            )
        }
    }

    useEffect(() => {
        if (!apiCalled) {
            getPendingTickets()
        }
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className='header-top text-center mt-10'>
                {currentFilter} - {filterTicketsList.length}
            </div>
            <div className='ml-5 mb-5 filter__bar__companies'>
                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <Autocomplete
                        disablePortal
                        id='combo-box-demo'
                        value={currentFilter}
                        options={filterList}
                        sx={{ width: '400px' }}
                        onChange={(event, item) => {
                            setCurrentFilter(item.label)
                            showAllFilterTickets(item.id)
                            secureLocalStorage.setItem('ticket_filter_id', item.id)
                        }}
                        renderInput={(params) => <TextField {...params} label='Filter' />}
                    />
                    <div className='text-center font-bold mt-2 mx-20'>
                        <span className='cursor-pointer hover:text-blue-600' onClick={redirectToClosedTicketsPage}>
                            View Closed Tickets
                        </span>
                    </div>
                </Grid>
            </div>
            <div className='all__tickets__container'>
                {filterTicketsList.length > 0 &&
                    filterTicketsList.map((ticket) => {
                        return (
                            <div class='tickets__card__container' onClick={() => openEachTicketContainer(ticket._id)}>
                                <article class='each__ticket__container'>
                                    <div>
                                        {showTicketTitle(ticket._id, ticket.userInfo.email, ticket.time, ticket.updatedAt, ticket.respondedAt, ticket.status, ticket.mailTicket, ticket.tag, ticket.appName)}
                                        {ticket.platform === 'ios' && <img className='device__icon mb-4' src={appleIcon} alt='' />}
                                        {ticket.platform === 'android' && <img className='device__icon mb-4' src={androidIcon} alt='' />}
                                        {ticket.platform === 'windows' && <img className='device__icon mb-4' src={windowsIcon} alt='' />}
                                        {ticket.platform === 'chrome' && <img className='device__icon mb-4' src={chromeIcon} alt='' />}
                                        <h2> {showTruncatedDescription(ticket.description)}</h2>
                                    </div>
                                    <div class='card-footer-snippets'>
                                        <div class='card-meta-snippets'>
                                            Created: {showTimeInAgoFormat(ticket.time)} &bull; {formatMillisecondsDate(ticket.time)}
                                        </div>
                                        <div class='card-meta-snippets font-bold'>User Response: {showTimeInAgoFormat(ticket.updatedAt)}</div>
                                        <div class='card-meta-snippets'>Support Team: {showTimeInAgoFormat(ticket.respondedAt)}</div>
                                        <div class='card-meta-snippets'>Status: {showTicketStatus(ticket.status)}</div>
                                        <div className='card-meta-snippets font-bold'>Assigned: {capitalizeFirstLetter(ticket.assigned)}</div>
                                        <div className='card-meta-snippets'>App Name: {ticket.appName}</div>
                                    </div>
                                </article>
                            </div>
                        )
                    })}
                {ticketsList.length === 0 && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '100px'
                        }}>
                        <CircularProgress size='7rem' color='grey' />
                    </Box>
                )}
            </div>
        </>
    )
}

export default ViewTickets

const filterList = [
    {
        label: 'All Tickets',
        id: 'all'
    },
    {
        label: 'Pending',
        id: 'pending'
    },
    {
        label: 'Tech Review',
        id: 'tech_review'
    },
    {
        label: 'Tech Complete',
        id: 'tech_complete'
    },
    {
        label: 'Awaiting User Reply',
        id: 'awaiting_user_reply'
    },
    {
        label: 'User Follow Up',
        id: 'user_follow_up'
    },
    {
        label: 'Oldest First',
        id: 'oldest_first'
    },
    {
        label: 'Agent Replied',
        id: 'agent_replied'
    },
    {
        label: 'Zenze App',
        id: 'zenze_app'
    }
]

const getLabelById = (id) => {
    const filter = filterList.find((item) => item.id === id)
    return filter ? filter.label : null
}
