import secureLocalStorage from 'react-secure-storage'
import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import MuiAlert from '@mui/material/Alert';
import { Avatar, Button, Card, CardContent, CardHeader, Grid, Input, Snackbar, Stack, Typography } from '@mui/material';

import { useEffect } from 'react';
import ReactGA from "react-ga";
import { useNavigate } from 'react-router-dom';
import { red } from '@mui/material/colors';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Grid align="center" justify="center" spacing={2}>
            <Typography>{children}</Typography>
          </Grid>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Partner() {

  const token = secureLocalStorage.getItem("token");
  let navigate = useNavigate();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [getPartnerUid, setGetPartnerUid] = useState("");
  const [addPartnerUid, setAddPartnerUid] = useState("");
  const [addPartnerEmail, setAddPartnerEmail] = useState("");
  const [removePartnerUid, setRemovePartnerUid] = useState("");

  const [partnerAfterGet, setPartnerAfterGet] = useState("");

  const dashboardUid = secureLocalStorage.getItem("uid");

  // Valid User Alert
  const [openValidUserAlert, setOpenValidUserAlert] = useState(false);
  const closeValidUserAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenValidUserAlert(false);
  };
  // Invalid User
  const [openInvalidUserAlert, setOpenInvalidUserAlert] = useState(false);
  const closeInvalidUserAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenInvalidUserAlert(false);
  }

  // Success
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const closeSuccessAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccessAlert(false);
  }

  // Error
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const closeErrorAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorAlert(false);
  }

  const getPartnerHandler = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
      body: JSON.stringify({ "uid": getPartnerUid })
    };

    fetch('https://healify.blockerx.net/api/dashboard/getPartner', requestOptions)
      .then(response => response.json())
      .then((data) => {
        if (data.status === "success" && data.data[0].partner) {
          setPartnerAfterGet(data.data[0].partner);
        } else if (data.status === "invalid_token") {
          secureLocalStorage.clear();
          navigate("/tokenExpired");
        } else {
          setGetPartnerUid("");
          setOpenErrorAlert(true);
        }
      });
  }

  const addPartnerHandler = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
      body: JSON.stringify({ "uid": addPartnerUid, "partner": addPartnerEmail })
    };

    fetch('https://healify.blockerx.net/api/dashboard/addPartner', requestOptions)
      .then(response => response.json())
      .then((data) => {
        if (data.status === "success") {
            setAddPartnerEmail('');
            setAddPartnerUid('');
            setOpenSuccessAlert(true);
        } else if (data.status === "invalid_token") {
          secureLocalStorage.clear();
          navigate("/tokenExpired");
        } else {
          setOpenErrorAlert(true);
        }
      });
  }

  const removePartnerHandler = () => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
      body: JSON.stringify({ "uid": removePartnerUid, "dashboardUid": dashboardUid })
    };

    fetch('https://dev.blockerx.net/api/dashboard/partner', requestOptions)
      .then(response => response.json())
      .then((data) => {
        console.log('Data : ', data)
        if (data.status === "success") {
            setRemovePartnerUid('');
            setOpenSuccessAlert(true);
        } else if (data.status === "invalid_token") {
          secureLocalStorage.clear();
          navigate("/tokenExpired");
        } else {
          setOpenErrorAlert(true);
        }
      });
  }
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);


  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Tabs value={value} onChange={handleChange} centered>
        <Tab label="Get Partner" />
        <Tab label="Add Partner" />
        <Tab label="Remove Partner" />
      </Tabs>

      <TabPanel value={value} index={0}>
        <Box sx={{ flexGrow: 2 }} >
          <Grid align="center" justify="center" spacing={2}>

            <Grid item lg={4} md={6} sm={12} className="pt-5">
              <h1 className="header-top mt-5">Get Accountability Partner</h1>
            </Grid>

            <Grid item lg={4} md={6} sm={12} className="pt-5">
              <Input
                disablePortal
                placeholder="Enter uid"
                id="combo-box-demo"
                sx={{ width: 400 }}
                // value={}
                onChange={(event, item) => {
                  setGetPartnerUid(event.target.value);
                }}
              />
            </Grid>

            <Grid item lg={4} md={6} sm={12} className="pt-5">
              <Button
                className="h-12"
                variant="contained"
                color='success'
                sx={{ width: 400 }}
                onClick={getPartnerHandler}>
                Get Partner
              </Button>
            </Grid>

            {partnerAfterGet.length > 1 ? 
            <Grid item lg={4} md={6} sm={12} className="pt-10">
            <Card sx={{ width: 400 }}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    {partnerAfterGet ? partnerAfterGet[0] : ""}
                  </Avatar>
                }
                title={"Partner : " + partnerAfterGet ? partnerAfterGet : ""}
                align="left"
              // subheader="Last Signed In : "
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Accountability Partner
                </Typography>
              </CardContent>
            </Card>
          </Grid> : ""}

          </Grid>
        </Box>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Box sx={{ flexGrow: 2 }} >
          <Grid align="center" justify="center" spacing={2}>

            <Grid item lg={4} md={6} sm={12} className="pt-5">
              <h1 className="header-top mt-5">Add Accountability Partner</h1>
            </Grid>

            <Grid item lg={4} md={6} sm={12} className="pt-5">
              <Input
                disablePortal
                placeholder="Enter uid"
                id="combo-box-demo"
                sx={{ width: 400 }}
                value={addPartnerUid}
                onChange={(event, item) => {
                  setAddPartnerUid(event.target.value);
                }}
              />
            </Grid>

            <Grid item lg={4} md={6} sm={12} className="pt-5">
              <Input
                disablePortal
                placeholder="Enter new partner email"
                id="combo-box-demo"
                sx={{ width: 400 }}
                value={addPartnerEmail}
                onChange={(event, item) => {
                  setAddPartnerEmail(event.target.value);
                }}
              />
            </Grid>

            {/* <Grid item lg={4} md={6} sm={12} className="pt-5">
              <Button
                className="h-12"
                variant="contained"
                sx={{ width: 400 }}
                // disabled={}
                onClick={{}}>
                Check User Validity
              </Button>
            </Grid> */}

            <Grid item lg={4} md={6} sm={12} className="pt-5">
              <Button
                className="h-12"
                variant="contained"
                sx={{ width: 400 }}
                onClick={addPartnerHandler}>
                Add Partner
              </Button>
            </Grid>
          </Grid>
        </Box>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Box sx={{ flexGrow: 2 }} >
          <Grid align="center" justify="center" spacing={2}>

            <Grid item lg={4} md={6} sm={12} className="pt-5">
              <h1 className="header-top mt-5">Remove Accountability Partner</h1>
            </Grid>

            <Grid item lg={4} md={6} sm={12} className="pt-5">
              <Input
                disablePortal
                placeholder="Enter uid"
                id="combo-box-demo"
                sx={{ width: 400 }}
                value={removePartnerUid}
                onChange={(event, item) => {
                  setRemovePartnerUid(event.target.value);
                }}
              />
            </Grid>

            <Grid item lg={4} md={6} sm={12} className="pt-5">
              <Button
                className="h-12"
                variant="contained"
                color='error'
                sx={{ width: 400 }}
                onClick={removePartnerHandler}>
                Remove Partner
              </Button>
            </Grid>
          </Grid>
        </Box>
      </TabPanel>

      {/* Alerts  */}

      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={openValidUserAlert} autoHideDuration={2000} onClose={closeValidUserAlert}>
          <Alert onClose={closeValidUserAlert} severity="success" sx={{ width: '100%' }}>
            User is valid!!
          </Alert>
        </Snackbar>
      </Stack>

      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={openInvalidUserAlert} autoHideDuration={2000} onClose={closeInvalidUserAlert}>
          <Alert onClose={closeInvalidUserAlert} severity="error" sx={{ width: '100%' }}>
            User is invalid!!
          </Alert>
        </Snackbar>
      </Stack>

      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
          <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
            Success!!!
          </Alert>
        </Snackbar>
      </Stack>

      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
          <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
            Error!!!
          </Alert>
        </Snackbar>
      </Stack>
    </Box>
  );
}