import React, { useState } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, TextField, Snackbar, Stack } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { BASE_URL } from '../../../api/Constants';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.action.hover,
    fontWeight: 'bold',
    fontSize: 16,
    color: theme.palette.text.primary,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.grey[100],
    },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UserActions = () => {
    const [uid, setUid] = useState('');
    const [actions, setActions] = useState([]);
    const [errorAlert, setErrorAlert] = useState(false);

    // Default actions if API call fails
    const defaultActions = [
        { action: 'Reset coins to zero', applicableStatus: true, value: 0 },
        { action: 'Turn off Premium', applicableStatus: false, value: 'N/A' },
        { action: 'Clear blocklist', applicableStatus: true, value: 'All' },
        { action: 'Clear streak history', applicableStatus: true, value: 'Last 30 days' },
    ];

    const handleApplyAction = (action) => {
        console.log(`Applying action: ${action}`);
    };

    const fetchUserActions = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v1/user/actions`, {
                params: { uid },
                headers: { 'Content-Type': 'application/json' }
            });

            if (response.data.status === 'success' && response.data.actions) {
                setActions(response.data.actions);
            } else {
                setErrorAlert(true);
                setActions(defaultActions);
            }
        } catch (error) {
            console.error('Failed to fetch user actions:', error);
            setErrorAlert(true);
            setActions(defaultActions);
        }
    };

    return (
        <Stack sx={{ p: 4, maxWidth: '90%', mx: 'auto', mt: 5, borderRadius: 2 }}>
            <Typography variant="h4" align="center" gutterBottom>
                User Actions
            </Typography>

            <Stack direction="row" spacing={2} sx={{ mb: 3, justifyContent: 'center' }}>
                <TextField
                    label="User UID"
                    variant="outlined"
                    value={uid}
                    onChange={(e) => setUid(e.target.value)}
                    sx={{ minWidth: '300px' }}
                />
                <Button variant="contained" color="primary" onClick={fetchUserActions}>
                    Get Actions
                </Button>
            </Stack>

            <TableContainer component={Paper} sx={{ borderRadius: 2, overflow: 'hidden' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Action</StyledTableCell>
                            <StyledTableCell>Applicable Status</StyledTableCell>
                            <StyledTableCell>Value</StyledTableCell>
                            <StyledTableCell>Apply</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(actions.length ? actions : defaultActions).map((item, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell>{item.action}</StyledTableCell>
                                <StyledTableCell>{item.applicableStatus ? 'True' : 'False'}</StyledTableCell>
                                <StyledTableCell>{item.value}</StyledTableCell>
                                <StyledTableCell>
                                    {item.applicableStatus && (
                                        <Button
                                            variant="contained"
                                            color="success"
                                            onClick={() => handleApplyAction(item.action)}
                                        >
                                            Apply
                                        </Button>
                                    )}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Error Snackbar */}
            <Snackbar open={errorAlert} autoHideDuration={3000} onClose={() => setErrorAlert(false)}>
                <Alert onClose={() => setErrorAlert(false)} severity="error">
                    Failed to fetch actions, showing default actions.
                </Alert>
            </Snackbar>
        </Stack>
    );
};

export default UserActions;
