import secureLocalStorage from 'react-secure-storage'
import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import MuiAlert from '@mui/material/Alert';
import { Button, Grid, Input, Snackbar, Stack, Typography } from '@mui/material';

import { useEffect } from 'react';
import ReactGA from "react-ga";
import { useNavigate } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Grid align="center" justify="center" spacing={2}>
                        <Typography>{children}</Typography>
                    </Grid>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function CenteredTabs() {

    const token = secureLocalStorage.getItem("token");
    let navigate = useNavigate();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [deleteUserEmail, setDeleteUserEmail] = useState("");
    const [deleteUserUid, setDeleteUserUid] = useState("");

    // Disabling button
    const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(true);
    const [userValiditiyButtonDisabled, setUserValidityButtonDisabled] = useState(false);

    // Alert

    // Valid User Alert
    const [openValidUserAlert, setOpenValidUserAlert] = useState(false);
    const closeValidUserAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenValidUserAlert(false);
    };

    // User already present in firebase
    const [openUserAlreadyPresentAlert, setOpenUserAlreadyPresentAlert] = useState(false);
    const closeUserAlreadyPresentAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenUserAlreadyPresentAlert(false);
    }

    // User has been created
    const [openUserCreatedAlert, setOpenUserCreatedAlert] = useState(false);
    const closeUserCreatedAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenUserCreatedAlert(false);
    }

    // Invalid User
    const [openInvalidUserAlert, setOpenInvalidUserAlert] = useState(false);
    const closeInvalidUserAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenInvalidUserAlert(false);
    }

    // User Deleted
    const [openDeletedUserAlert, setOpenDeletedUserAlert] = useState(false);
    const closeDeletedUserAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenDeletedUserAlert(false);
    }

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const checkUserValidityHandler = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "email": deleteUserEmail })
        };

        fetch('https://healify.blockerx.net/api/dashboard/firebase/userInfo', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === "success") {
                    setDeleteUserUid(data.data.uid);
                    setDeleteButtonDisabled(false);
                    setUserValidityButtonDisabled(true);
                    setOpenValidUserAlert(true);
                } else if (data.status === "invalid_token") {
                    secureLocalStorage.clear();
                    navigate("/tokenExpired");
                } else {
                    setOpenInvalidUserAlert(true);
                }
            });
    }

    const deleteFirebaseUserHandler = () => {

        if (userValiditiyButtonDisabled === false) {
            setOpenInvalidUserAlert(true);
            return;
        }

        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
        //     body: JSON.stringify({ "uid": deleteUserUid })
        // };

        // fetch('https://healify.blockerx.net/api/dashboard/firebase/deleteUser', requestOptions)
        //     .then(response => response.json())
        //     .then((data) => {
        //         console.log(data);
        //         if (data.status === "success") {
        //             setOpenDeletedUserAlert(true);
        //             setDeleteUserEmail("");
        //             setDeleteButtonDisabled(true);
        //             setUserValidityButtonDisabled(false);
        //         } else {
        //             setOpenInvalidUserAlert(true);
        //             setDeleteUserEmail("");
        //             setDeleteButtonDisabled(true);
        //             setUserValidityButtonDisabled(false);
        //         }
        //     });
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);


    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Tabs value={value} onChange={handleChange} centered>
                <Tab label="Clear Data" />
                {/* <Tab label="Remove Website" /> */}
            </Tabs>
            <TabPanel value={value} index={0}>
                <Box sx={{ flexGrow: 2 }} >
                    <Grid align="center" justify="center" spacing={2}>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <h1 className="header-top mt-5">Clear MongoDB Data</h1>
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <Input
                                disablePortal
                                placeholder="Enter uid"
                                id="combo-box-demo"
                                sx={{ width: 400 }}
                                value={deleteUserEmail}
                                onChange={(event, item) => {
                                    setDeleteUserEmail(event.target.value);
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <Button
                                className="h-12"
                                variant="contained"
                                sx={{ width: 400 }}
                                disabled={userValiditiyButtonDisabled}
                                onClick={checkUserValidityHandler}>
                                Check User Validity
                            </Button>
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <Button
                                className="h-12"
                                variant="contained"
                                color='error'
                                disabled={deleteButtonDisabled}
                                sx={{ width: 400 }}
                                onClick={deleteFirebaseUserHandler}>
                                Delete User
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </TabPanel>

            <TabPanel value={value} index={1}>
                Under Development
            </TabPanel>

            {/* Alerts  */}

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openValidUserAlert} autoHideDuration={2000} onClose={closeValidUserAlert}>
                    <Alert onClose={closeValidUserAlert} severity="success" sx={{ width: '100%' }}>
                        User is valid!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openUserCreatedAlert} autoHideDuration={2000} onClose={closeUserCreatedAlert}>
                    <Alert onClose={closeUserCreatedAlert} severity="success" sx={{ width: '100%' }}>
                        User has been created!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openUserAlreadyPresentAlert} autoHideDuration={2000} onClose={closeUserAlreadyPresentAlert}>
                    <Alert onClose={closeUserAlreadyPresentAlert} severity="warning" sx={{ width: '100%' }}>
                        User already present!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openInvalidUserAlert} autoHideDuration={2000} onClose={closeInvalidUserAlert}>
                    <Alert onClose={closeInvalidUserAlert} severity="error" sx={{ width: '100%' }}>
                        User is invalid!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openDeletedUserAlert} autoHideDuration={2000} onClose={closeDeletedUserAlert}>
                    <Alert onClose={closeDeletedUserAlert} severity="success" sx={{ width: '100%' }}>
                        User has been deleted!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                        Success!!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                        Error!!!
                    </Alert>
                </Snackbar>
            </Stack>
        </Box>
    );
}