import React, { useState } from 'react';
import {
    TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Switch
} from '@mui/material';
import { Edit as EditIcon, Add as AddIcon } from '@mui/icons-material';
import axios from 'axios';
import { format } from 'date-fns';
import { showTimeInAgoFormat } from '../../../Utils/time';
import { ZENZE_BASE_URL } from '../../../api/Constants';

const formatFieldName = (fieldName) => {
    return fieldName
        .replace(/_/g, ' ')             // Replace underscores with spaces
        .replace(/([A-Z])/g, ' $1')     // Add a space before uppercase letters
        .replace(/^./, (str) => str.toUpperCase());  // Capitalize the first letter
};

// Function to format milliseconds to date string
const formatDate = (milliseconds) => {
    if (milliseconds === 0) {
        return ''
    }
    return showTimeInAgoFormat(milliseconds) + ' on ' + format(new Date(milliseconds), 'MMMM d, yyyy h:mm a');
};

const ZenzeUserInfo = () => {
    const [uid, setUid] = useState('');
    const [userInfo, setUserInfo] = useState(null);
    const [error, setError] = useState('');
    const [editUsernameOpen, setEditUsernameOpen] = useState(false);
    const [addCoinsOpen, setAddCoinsOpen] = useState(false);
    const [extendTrialOpen, setExtendTrialOpen] = useState(false);
    const [newUsername, setNewUsername] = useState('');
    const [coinsToAdd, setCoinsToAdd] = useState('');
    const [daysToExtend, setDaysToExtend] = useState('');

    const handleGetUserInfo = async () => {
        try {
            const response = await axios.get(`${ZENZE_BASE_URL}/api/dashboard/v1/user?id=${uid}`);
            if (response.data.status === 200) {
                console.log('response.data.data.userInfo : ', response.data.data.userInfo)
                setUserInfo(response.data.data.userInfo);
                setError('');
            } else {
                setError('Failed to fetch user info');
                setUserInfo(null);
            }
        } catch (error) {
            setError('Failed to fetch user info');
            setUserInfo(null);
        }
    };

    const handleUsernameUpdate = async () => {
        try {
            await axios.post(`${ZENZE_BASE_URL}/api/dashboard/v1/username`, {
                uid: userInfo.uid,
                oldUsername: userInfo.username,
                newUsername
            });
            setUserInfo({ ...userInfo, username: newUsername });
            setEditUsernameOpen(false);
        } catch (error) {
            console.error('Failed to update username', error);
        }
    };

    const handleAddCoins = async () => {
        try {
            await axios.post(`${ZENZE_BASE_URL}/api/dashboard/v1/coins`, {
                uid: userInfo.uid,
                coins: parseInt(coinsToAdd, 10)
            });
            setUserInfo({ ...userInfo, coins: userInfo.coins + parseInt(coinsToAdd, 10) });
            setAddCoinsOpen(false);
        } catch (error) {
            console.error('Failed to add coins', error);
        }
    };

    const handleExtendTrial = async () => {
        try {
            console.log('userInfo: ', userInfo)
            await axios.post(`${ZENZE_BASE_URL}/api/dashboard/v1/extend-freetrial`, {
                uid: userInfo.uid,
                extendDays: parseInt(daysToExtend, 10)
            });
            setExtendTrialOpen(false);
            // Assuming freeTrialExpiry is updated on server-side, fetch the latest user info
            handleGetUserInfo();
        } catch (error) {
            console.error('Failed to extend free trial', error);
        }
    };

    const handleToggleSwitch = async (switchName, currentValue) => {
        try {
            const requestBody = {
                "uid": userInfo.uid,
                "switchName": switchName,
                "flag": !currentValue
            };
            console.log('Request Body : ', requestBody);

            // Adding Content-Type in the axios request configuration
            await axios.post(`${ZENZE_BASE_URL}/api/v1/dashboard/force-switches`, requestBody, {
                headers: {
                    'Content-Type': 'application/json' // Set the Content-Type header
                }
            });

            // Update the switch state after successful API call
            setUserInfo({
                ...userInfo,
                forceSwitches: {
                    ...userInfo.forceSwitches,
                    [switchName]: !currentValue
                }
            });
        } catch (error) {
            console.error('Failed to toggle switch', error);
        }
    };

    const hiddenKeys = ['authToken', '_id'];
    const date_keys = ['lastActive', 'createdAt', 'freeTrialExpiry', 'lastStreakReset'];

    // Recursive function to render nested fields
    const renderNestedFields = (object, parentKey) => {
        return Object.entries(object).map(([key, value]) => {
            if (typeof value === 'object' && value !== null) {
                return (
                    <React.Fragment key={`${parentKey}-${key}`}>
                        <TableRow>
                            <TableCell colSpan={2} sx={{ fontWeight: 'bolder' }}>{formatFieldName(`${parentKey} - ${key}`)}</TableCell>
                        </TableRow>
                        {renderNestedFields(value, `${parentKey}-${key}`)}
                    </React.Fragment>
                );
            } else {
                if (key === 'lastActive' || key === 'createdAt' || key === 'updatedAt') {
                    value = formatDate(value); // Format date fields
                }
                return (
                    <TableRow key={`${parentKey}-${key}`}>
                        <TableCell>{formatFieldName(key)}</TableCell>
                        <TableCell>{String(value)}</TableCell>
                    </TableRow>
                );
            }
        });
    };

    // Separate Android and iOS fields from other user info
    const androidData = userInfo?.android || null;
    const iosData = userInfo?.ios || null;
    const forceSwitchesData = userInfo?.forceSwitches || null;

    return (
        <div className="flex flex-col items-center my-16">
            <TextField
                label="UID/Email"
                variant="outlined"
                value={uid}
                onChange={(e) => setUid(e.target.value)}
                sx={{ mb: '10px', width: '40%' }}
            />
            <Button variant="contained" color="primary" sx={{ mb: '10px', width: '40%' }} onClick={handleGetUserInfo}>
                Get User Info
            </Button>
            {error && <p className="text-red-500 mt-4">{error}</p>}
            {userInfo && (
                <>
                    <TableContainer component={Paper} sx={{ width: '90%', marginTop: '50px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bolder' }}>Field</TableCell>
                                    <TableCell sx={{ fontWeight: 'bolder' }}>Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.entries(userInfo).map(([key, value]) => {
                                    if (hiddenKeys.includes(key)) return null; // Hide authToken and _id
                                    if (key === 'android' || key === 'ios' || key === 'forceSwitches') return null; // Skip android/ios for now
                                    if (date_keys.includes(key)) {
                                        value = formatDate(value); // Format date fields
                                    }
                                    if (typeof value === 'object' && value !== null) {
                                        // Render nested objects
                                        return (
                                            <React.Fragment key={key}>
                                                <TableRow>
                                                    <TableCell colSpan={2} sx={{ fontWeight: 'bolder' }}>{formatFieldName(key)}</TableCell>
                                                </TableRow>
                                                {renderNestedFields(value, key)}
                                            </React.Fragment>
                                        );
                                    }
                                    return (
                                        <TableRow key={key}>
                                            <TableCell>
                                                {formatFieldName(key)}
                                                {key === 'username' && (
                                                    <IconButton size="small" onClick={() => setEditUsernameOpen(true)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                )}
                                                {key === 'coins' && (
                                                    <IconButton size="small" onClick={() => setAddCoinsOpen(true)}>
                                                        <AddIcon />
                                                    </IconButton>
                                                )}
                                                {key === 'freeTrialExpiry' && (
                                                    <IconButton size="small" onClick={() => setExtendTrialOpen(true)}>
                                                        <AddIcon />
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                            <TableCell>{String(value)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Render Android info in a separate table */}
                    {androidData && (
                        <TableContainer component={Paper} sx={{ width: '90%', marginTop: '20px' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bolder' }}>Android Field</TableCell>
                                        <TableCell sx={{ fontWeight: 'bolder' }}>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {renderNestedFields(androidData, 'android')}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}

                    {/* Render Force Switches with Toggle */}
                    {forceSwitchesData && (
                        <TableContainer component={Paper} sx={{ width: '90%', marginTop: '20px' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bolder' }}>Force Switches</TableCell>
                                        <TableCell sx={{ fontWeight: 'bolder' }}>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(forceSwitchesData).map(([switchName, flag]) => (
                                        <TableRow key={switchName}>
                                            <TableCell>{formatFieldName(switchName)}</TableCell>
                                            <TableCell>
                                                <Switch
                                                    checked={flag}
                                                    onChange={() => handleToggleSwitch(switchName, flag)}
                                                    color="primary"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}

                    {/* Render iOS info in a separate table */}
                    {iosData && (
                        <TableContainer component={Paper} sx={{ width: '90%', marginTop: '20px' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bolder' }}>iOS Field</TableCell>
                                        <TableCell sx={{ fontWeight: 'bolder' }}>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {renderNestedFields(iosData, 'ios')}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </>
            )}

            {/* Dialogs for editing username, adding coins, and extending free trial */}
            <Dialog open={editUsernameOpen} onClose={() => setEditUsernameOpen(false)}>
                <DialogTitle>Edit Username</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the new username you want to set.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="New Username"
                        type="text"
                        fullWidth
                        value={newUsername}
                        onChange={(e) => setNewUsername(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditUsernameOpen(false)}>Cancel</Button>
                    <Button onClick={handleUsernameUpdate}>Update</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={addCoinsOpen} onClose={() => setAddCoinsOpen(false)}>
                <DialogTitle>Add Coins</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the number of coins you want to add.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Coins"
                        type="number"
                        fullWidth
                        value={coinsToAdd}
                        onChange={(e) => setCoinsToAdd(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddCoinsOpen(false)}>Cancel</Button>
                    <Button onClick={handleAddCoins}>Add</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={extendTrialOpen} onClose={() => setExtendTrialOpen(false)}>
                <DialogTitle>Extend Free Trial</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the number of days to extend the free trial.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Days"
                        type="number"
                        fullWidth
                        value={daysToExtend}
                        onChange={(e) => setDaysToExtend(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setExtendTrialOpen(false)}>Cancel</Button>
                    <Button onClick={handleExtendTrial}>Extend</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ZenzeUserInfo;
