import { Autocomplete, Button, Snackbar, Stack, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AppSetupAutocomplete, ArticleVideoCourseAutocomplete, BlockWhiteListPageAutoComplete, BlockWindowPageAutocomplete, CommunicationAutocomplete, CommunityPageAutocomplete, CustomWallpaperAutocomplete, DealingWithUrgesAutocomplete, FeedAutocomplete, HelpAutocomplete, HomePageAutocomplete, JournalAutocomplete, MainActivityAutocomplete, MainPageAutocomplete, NotificationActionAutocomplete, NpsNumberAutocomplete, OtherAutocomplete, PornMasturbationAutocomplete, PurchasePremiumAutocomplete, RatingAutocomplete, ReferEarnAutocomplete, SettingPageAutocomplete, SignInSignUpAutocomplete, StreakAutocomplete, SwitchPageAutocomplete, UserProfileAutocomplete, WidgetAutocomplete, cohortProgramAutocomplete, courseFeedbackAutocomplete, currentCampaignTypeAutocomplete, eventList, propertyList, video_watch_idAutocomplete } from '../../arrays/customEvents'

import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomAlerts = () => {
    const [successMessage, setSuccessMessage] = useState('Success!')
    const [errorMessage, setErrorMessage] = useState('Error!')
    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }
    return (
        <>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                       {errorMessage}
                    </Alert>
                </Snackbar>
            </Stack>
        </>
    )
}

function PushNotifications() {

    const [country, setCountry] = useState('All Countries')
    const [campaignsList, setCampaignsList] = useState([{ label: 'campaign_list_usa' }])
    const [currentCampaign, setCurrentCampaign] = useState('campaign_list_usa')
    const [emailTemplateListAutocomplete, setEmailTemplateListAutocomplete] = useState([{ label: 'customer_support_feedback' }, { label: 'no_nut_november_premium_campaign' }])
    const [currentEmailTemplate, setCurrentEmailTemplate] = useState('customer_support_feedback')
    const [notificationTemplateAutocomplete, setNotificationTemplateAutocomplete] = useState([{ label: 'notification_template' }])
    const [currentNotificationTemplate, setCurrentNotificationTemplate] = useState('notification_template')
    const [currentTemplate, setCurrentTemplate] = useState('customer_support_feedback')
    const [currentCampaignType, setCurrentCampaignType] = useState('Email')
    const [apiCalled, setApiCalled] = useState(false)
    const [campaignName, setCampaignName] = useState('')
    const [currentEvent, setCurrentEvent] = useState('MainActivity')
    const [currentProperty, setCurrentProperty] = useState('click')
    const [currentPropertyValue, setCurrentPropertyValue] = useState('MainActivity_click_BLOCKING')
    const [campaignStartDate, setCampaignStartDate] = useState('')
    const [campaignEndDate, setCampaignEndDate] = useState('')
    const [valuesAutocompleteList, setValuesAutocompleteList] = useState([
        { label: 'MainActivity_click_BLOCKING' },
        { label: 'MainActivity_click_BLOCK_ITEM' },
        { label: 'MainActivity_click_COMMUNITY' },
        { label: 'MainActivity_click_HOME' },
        { label: 'MainActivity_click_PREMIUM' },
        { label: 'MainActivity_coin_dashboard_clicked' },
        { label: 'MainActivity_enter_username_navigation_drawer' },
        { label: 'MainActivity_faq_clicked' },
        { label: 'MainActivity_hamburger_open' },
        { label: 'MainActivity_last_screen_visited_ArticleListPageFragment' },
        {
            label: 'MainActivity_last_screen_visited_BlockMeScheduleTimeFragment'
        },
        {
            label: 'MainActivity_last_screen_visited_BlockerXInAppBrowserBlockingPageFragment'
        },
        {
            label: 'MainActivity_last_screen_visited_BlockerXLandingPageFragment'
        },
        {
            label: 'MainActivity_last_screen_visited_BlockerxTransparentActivity'
        },
        { label: 'MainActivity_last_screen_visited_BuyCoinsDialog' },
        { label: 'MainActivity_last_screen_visited_CourseDetailFragment' },
        {
            label: 'MainActivity_last_screen_visited_DealingWithUrgesFragment'
        },
        {
            label: 'MainActivity_last_screen_visited_DialogFeedSetUserNameFragment'
        },
        { label: 'MainActivity_last_screen_visited_FaqFragment' },
        { label: 'MainActivity_last_screen_visited_FeedDetailsFragment' },
        { label: 'MainActivity_last_screen_visited_FeedDisplayActivity' },
        { label: 'MainActivity_last_screen_visited_FeedDisplayFragment' },
        {
            label: 'MainActivity_last_screen_visited_FeedDisplayFullImageFragment'
        },
        { label: 'MainActivity_last_screen_visited_FeedImagePostFragment' },
        {
            label: 'MainActivity_last_screen_visited_FeedLaunchConditionFragment'
        },
        { label: 'MainActivity_last_screen_visited_FeedPollPostFragment' },
        { label: 'MainActivity_last_screen_visited_FeedPostingFragment' },
        { label: 'MainActivity_last_screen_visited_FeedTagListFragment' },
        {
            label: 'MainActivity_last_screen_visited_FeedVideoPostFullScreenFragment'
        },
        { label: 'MainActivity_last_screen_visited_GoalSettingPageFragment' },
        {
            label: 'MainActivity_last_screen_visited_IntroPopupLeastPriceDialog'
        },
        { label: 'MainActivity_last_screen_visited_LoadAllWebViewFragment' },
        {
            label: 'MainActivity_last_screen_visited_NewBlockerXSettingFragment'
        },
        {
            label: 'MainActivity_last_screen_visited_NewKeywordWebsiteAppListMainFragment'
        },
        {
            label: 'MainActivity_last_screen_visited_NewMyAccountSettingFragment'
        },
        {
            label: 'MainActivity_last_screen_visited_NewPersonalJournalMainFragment'
        },
        {
            label: 'MainActivity_last_screen_visited_NewPurchasePremiumFragment'
        },
        {
            label: 'MainActivity_last_screen_visited_NofapLifetimePurchesDialog'
        },
        { label: 'MainActivity_last_screen_visited_NotificationFragment' },
        { label: 'MainActivity_last_screen_visited_OtherBlockingFragment' },
        {
            label: 'MainActivity_last_screen_visited_PornAddictionTestFragment'
        },
        {
            label: 'MainActivity_last_screen_visited_PornMasturbationInsightsFragment'
        },
        { label: 'MainActivity_last_screen_visited_RebootNowFragment' },
        { label: 'MainActivity_last_screen_visited_ReferEarnActivity' },
        {
            label: 'MainActivity_last_screen_visited_SelfUpvotedCommnetedPostsFragment'
        },
        {
            label: 'MainActivity_last_screen_visited_SetDefaultBrowseFragment'
        },
        {
            label: 'MainActivity_last_screen_visited_SignInSignUpGlobalFragment'
        },
        {
            label: 'MainActivity_last_screen_visited_SignInSignUpWhileAppStartActivity'
        },
        { label: 'MainActivity_last_screen_visited_StreakHistoryFragment' },
        {
            label: 'MainActivity_last_screen_visited_StreakLeaderBoardFragment'
        },
        { label: 'MainActivity_last_screen_visited_SwitchPageFragment' },
        { label: 'MainActivity_last_screen_visited_UserProfileFragment' },
        {
            label: 'MainActivity_last_screen_visited_VideoCourseListPageFragment'
        },
        { label: 'MainActivity_login_clicked' },
        { label: 'MainActivity_notification_clicked' },
        { label: 'MainActivity_null_clicked' },
        { label: 'MainActivity_open_setting_navigation_drawer' },
        { label: 'MainActivity_other_blocking_clicked' },
        { label: 'MainActivity_premium_clicked' },
        { label: 'MainActivity_settings_clicked' },
        { label: 'MainActivity_sign_in_navigation_drawer' },
        { label: 'MainActivity_support_clicked' },
        { label: 'MainActivity_user_feedback_clicked' }
    ])
    const [countryId, setCountryId] = useState('all')
    const [eventCount, setEventCount] = useState(0)
    const [timeFilterLabel, setTimeFilterLabel] = useState('Last 12 hours')
    const [timeFilterDays, setTimeFilterDays] = useState(0.5)
    const [showCount, setShowCount] = useState(false)

    const [campaignType, setCampaignType] = React.useState('schedule_now');

    const [successMessage, setSuccessMessage] = useState('Success!')
    const [errorMessage, setErrorMessage] = useState('Error!')
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const clearFilledValues = () => {
        setCampaignName('')
    }

    const handleTypeOfCampaign = (event) => {
        setCampaignType(event.target.value);
    };

    const setValuesBasedOnEvent = (LABEL) => {
        if (LABEL === 'ArticleVideoCourse') {
            setValuesAutocompleteList(ArticleVideoCourseAutocomplete)
            setCurrentPropertyValue(ArticleVideoCourseAutocomplete[0].label)
        } else if (LABEL === 'BlockWhiteListPage') {
            setValuesAutocompleteList(BlockWhiteListPageAutoComplete)
            setCurrentPropertyValue(BlockWhiteListPageAutoComplete[0].label)
        } else if (LABEL === 'CommunityPage') {
            setValuesAutocompleteList(CommunityPageAutocomplete)
            setCurrentPropertyValue(CommunityPageAutocomplete[0].label)
        } else if (LABEL === 'CustomWallpaper') {
            setValuesAutocompleteList(CustomWallpaperAutocomplete)
            setCurrentPropertyValue(CustomWallpaperAutocomplete[0].label)
        } else if (LABEL === 'Feed') {
            setValuesAutocompleteList(FeedAutocomplete)
            setCurrentPropertyValue(FeedAutocomplete[0].label)
        } else if (LABEL === 'MainPage') {
            setValuesAutocompleteList(MainPageAutocomplete)
            setCurrentPropertyValue(MainPageAutocomplete[0].label)
        } else if (LABEL === 'NotificationAction') {
            setValuesAutocompleteList(NotificationActionAutocomplete)
            setCurrentPropertyValue(NotificationActionAutocomplete[0].label)
        } else if (LABEL === 'Other') {
            setValuesAutocompleteList(OtherAutocomplete)
            setCurrentPropertyValue(OtherAutocomplete[0].label)
        } else if (LABEL === 'PurchasePremium') {
            setValuesAutocompleteList(PurchasePremiumAutocomplete)
            setCurrentPropertyValue(PurchasePremiumAutocomplete[0].label)
        } else if (LABEL === 'Rating') {
            setValuesAutocompleteList(RatingAutocomplete)
            setCurrentPropertyValue(RatingAutocomplete[0].label)
        } else if (LABEL === 'ReferEarn') {
            setValuesAutocompleteList(ReferEarnAutocomplete)
            setCurrentPropertyValue(ReferEarnAutocomplete[0].label)
        } else if (LABEL === 'SettingPage') {
            setValuesAutocompleteList(SettingPageAutocomplete)
            setCurrentPropertyValue(SettingPageAutocomplete[0].label)
        } else if (LABEL === 'SignInSignUp') {
            setValuesAutocompleteList(SignInSignUpAutocomplete)
            setCurrentPropertyValue(SignInSignUpAutocomplete[0].label)
        } else if (LABEL === 'UserProfile') {
            setValuesAutocompleteList(UserProfileAutocomplete)
            setCurrentPropertyValue(UserProfileAutocomplete[0].label)
        } else if (LABEL === 'cohortProgram') {
            setValuesAutocompleteList(cohortProgramAutocomplete)
            setCurrentPropertyValue(cohortProgramAutocomplete[0].label)
        } else if (LABEL === 'video_watch_id') {
            setValuesAutocompleteList(video_watch_idAutocomplete)
            setCurrentPropertyValue(video_watch_idAutocomplete[0].label)
        } else if (LABEL === 'AppSetup') {
            setValuesAutocompleteList(AppSetupAutocomplete)
            setCurrentPropertyValue(AppSetupAutocomplete[0].label)
        } else if (LABEL === 'BlockWindowPage') {
            setValuesAutocompleteList(BlockWindowPageAutocomplete)
            setCurrentPropertyValue(BlockWindowPageAutocomplete[0].label)
        } else if (LABEL === 'Communication') {
            setValuesAutocompleteList(CommunicationAutocomplete)
            setCurrentPropertyValue(CommunicationAutocomplete[0].label)
        } else if (LABEL === 'DealingWithUrges') {
            setValuesAutocompleteList(DealingWithUrgesAutocomplete)
            setCurrentPropertyValue(DealingWithUrgesAutocomplete[0].label)
        } else if (LABEL === 'Help') {
            setValuesAutocompleteList(HelpAutocomplete)
            setCurrentPropertyValue(HelpAutocomplete[0].label)
        } else if (LABEL === 'HomePage') {
            setValuesAutocompleteList(HomePageAutocomplete)
            setCurrentPropertyValue(HomePageAutocomplete[0].label)
        } else if (LABEL === 'Journal') {
            setValuesAutocompleteList(JournalAutocomplete)
            setCurrentPropertyValue(JournalAutocomplete[0].label)
        } else if (LABEL === 'MainActivity') {
            setValuesAutocompleteList(MainActivityAutocomplete)
            setCurrentPropertyValue(MainActivityAutocomplete[0].label)
        } else if (LABEL === 'NpsNumber') {
            setValuesAutocompleteList(NpsNumberAutocomplete)
            setCurrentPropertyValue(NpsNumberAutocomplete[0].label)
        } else if (LABEL === 'PornMasturbation') {
            setValuesAutocompleteList(PornMasturbationAutocomplete)
            setCurrentPropertyValue(PornMasturbationAutocomplete[0].label)
        } else if (LABEL === 'Streak') {
            setValuesAutocompleteList(StreakAutocomplete)
            setCurrentPropertyValue(StreakAutocomplete[0].label)
        } else if (LABEL === 'SwitchPage') {
            setValuesAutocompleteList(SwitchPageAutocomplete)
            setCurrentPropertyValue(SwitchPageAutocomplete[0].label)
        } else if (LABEL === 'Widget') {
            setValuesAutocompleteList(WidgetAutocomplete)
            setCurrentPropertyValue(WidgetAutocomplete[0].label)
        } else if (LABEL === 'courseFeedback') {
            setValuesAutocompleteList(courseFeedbackAutocomplete)
            setCurrentPropertyValue(courseFeedbackAutocomplete[0].label)
        } else {
            setValuesAutocompleteList([])
            setCurrentPropertyValue('')
        }
    }

    const getCountOfCurrentPropertyValue = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "name": currentEvent, "property": currentProperty, "value": currentPropertyValue, "time": new Date().getTime() - (timeFilterDays * 86400000) })
        };

        fetch('https://events.blockerx.net/api/events/analysis/single', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setEventCount(data.data.count)
                    setShowCount(true)
                } else {

                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const isEmptyString = (str) => {
        try {
            if (str === '') {
                return true;
            }
            return false
        } catch {
            return false
        }
    }

    const createNewCampaignHandler = () => {
        if (isEmptyString(campaignStartDate) || isEmptyString(campaignEndDate) || isEmptyString(currentTemplate)) {
            setErrorMessage('All fields are required!!')
            setOpenErrorAlert(true)
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "name": campaignName,
                "event_name": currentEvent,
                "event_property": currentProperty,
                "event_value": currentPropertyValue,
                "type": currentCampaignType,
                "start_date": campaignStartDate,
                "end_date": campaignEndDate,
                "template": currentTemplate
            })
        };

        fetch('https://events.blockerx.net/api/events/campaigns', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    clearFilledValues()
                } else {

                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const getCountButtonHandler = () => {
        getCountOfCurrentPropertyValue()
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className='campaign__container'>
                <div className='campaign__heading font-bold text-4xl my-6'>
                    Create New Campaign
                </div>
                <div className='event__selection__container'>
                    <TextField
                        disablePortal
                        placeholder="Campaign name"
                        id="combo-box-demo"
                        value={campaignName}
                        sx={{ width: "30%" }}
                        onChange={(event, item) => {
                            setCampaignName(event.target.value)
                        }}
                    />
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={currentEvent}
                        options={eventList}
                        sx={{ width: "30%" }}
                        onChange={(event, item) => {
                            setCurrentEvent(item.label)
                            setValuesBasedOnEvent(item.label)
                            setShowCount(false)
                        }}
                        renderInput={(params) => <TextField {...params} label="Select Event" />}
                    />
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={currentProperty}
                        options={propertyList}
                        sx={{ width: "30%" }}
                        onChange={(event, item) => {
                            setCurrentProperty(item.label)
                            setShowCount(false)
                        }}
                        renderInput={(params) => <TextField {...params} label="Select Property" />}
                    />
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={currentPropertyValue}
                        options={valuesAutocompleteList}
                        sx={{ width: "30%" }}
                        onChange={(event, item) => {
                            console.log('Change ')
                            setCurrentPropertyValue(item.label)
                            setEventCount(0)
                            setShowCount(false)
                        }}
                        renderInput={(params) => <TextField {...params} label="Select Event Property" />}
                    />
                    <TextField
                        label="Start Date"
                        type='date'
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: "30%" }}
                        onChange={(event, item) => {
                            const myDate = event.target.value.split("-");
                            const newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]).getTime();
                            setCampaignStartDate(newDate);
                        }}
                    />

                    <TextField
                        label="End Date"
                        id="combo-box-demo"
                        type='date'
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: "30%" }}
                        onChange={(event, item) => {
                            const myDate = event.target.value.split("-");
                            const newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]).getTime();
                            setCampaignEndDate(newDate)
                        }}
                    />
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={currentCampaignType}
                        options={currentCampaignTypeAutocomplete}
                        sx={{ width: "30%" }}
                        onChange={(event, item) => {
                            setCurrentCampaignType(item.label)
                        }}
                        renderInput={(params) => <TextField {...params} label="Select campaign type" />}
                    />
                    {
                        currentCampaignType === 'Notification' && <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            value={currentNotificationTemplate}
                            options={notificationTemplateAutocomplete}
                            sx={{ width: "30%" }}
                            onChange={(event, item) => {
                                setCurrentNotificationTemplate(item.label)
                                setCurrentTemplate(item.label)
                            }}
                            renderInput={(params) => <TextField {...params} label="Select notification template" />}
                        />
                    }

                    {
                        currentCampaignType === 'Email' && <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            value={currentEmailTemplate}
                            options={emailTemplateListAutocomplete}
                            sx={{ width: "30%" }}
                            onChange={(event, item) => {
                                setCurrentEmailTemplate(item.label)
                                setCurrentTemplate(item.label)
                            }}
                            renderInput={(params) => <TextField {...params} label="Select email template" />}
                        />
                    }
                    <Button sx={{ width: "30%", height: "50px", fontSize: "20px" }} variant='contained' onClick={createNewCampaignHandler}>Create Campaign</Button>
                </div>
            </div>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </Stack>
        </>
    )
}

export default PushNotifications