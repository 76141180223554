import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { BASE_URL } from '../../api/Constants';

const userAvatar = 'https://img.freepik.com/free-vector/blue-circle-with-white-user_78370-4707.jpg'; // Replace with a Pexels avatar URL for the user
const ziaAvatar = 'https://preview.redd.it/cute-ai-girl-v0-wpc4vgks91ob1.png?auto=webp&s=0df5ccfa32a7bce9606735fee52bf9a1254dd3d5'; // Replace with a Pexels avatar URL for Zia

function ZiaConversation() {
    const [chatHistory, setChatHistory] = useState([]);
    const location = useLocation();
    const chatEndRef = useRef(null);

    // Function to get query parameters
    const getQueryParams = (query) => {
        return new URLSearchParams(query);
    };

    useEffect(() => {
        const queryParams = getQueryParams(location.search);
        const uid = queryParams.get('uid');

        if (uid) {
            // Call the API to fetch chat history
            axios.get(`${BASE_URL}/api/ai-friend/v1/chat-history?uid=${uid}`)
                .then(response => {
                    if (response.status === 200 && response.data.message === 'success') {
                        setChatHistory(response.data.data.history);
                    }
                })
                .catch(error => {
                    console.error('Error fetching chat history:', error);
                });
        }
    }, [location.search]);

    useEffect(() => {
        // Scroll to bottom when chat history updates
        chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [chatHistory]);

    return (
        <div style={{ padding: '20px', backgroundColor: '#f0f2f5', borderRadius: '10px', display: 'flex', flexDirection: 'column' }}>
            <div style={{ flex: 1, overflowY: 'auto', padding: '10px', backgroundColor: '#ffffff', borderRadius: '10px' }}>
                {chatHistory.map((chat, index) => (
                    <div
                        key={index}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: chat.role === 'user' ? 'flex-start' : 'flex-end',
                            margin: '10px 0'
                        }}
                    >
                        {chat.role === 'user' && (
                            <img
                                src={userAvatar}
                                alt="User Avatar"
                                style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }}
                            />
                        )}
                        <div style={{
                            maxWidth: '60%',
                            wordBreak: 'break-word',
                            backgroundColor: chat.role === 'user' ? '#e6f7ff' : '#d9f7be',
                            padding: '10px',
                            borderRadius: '10px',
                            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
                        }}>
                            <strong>{chat.role === 'user' ? '' : ''}</strong> {chat.message}
                            <br />
                            <small style={{ color: '#888' }}>{formatDistanceToNow(new Date(chat.time), { addSuffix: true })}</small>
                        </div>
                        {chat.role === 'model' && (
                            <img
                                src={ziaAvatar}
                                alt="Zia Avatar"
                                style={{ width: '40px', height: '40px', borderRadius: '50%', marginLeft: '10px' }}
                            />
                        )}
                    </div>
                ))}
                <div ref={chatEndRef}></div>
            </div>
        </div>
    );
}

export default ZiaConversation;
